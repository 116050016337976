import { default as _91_91slug_93_93Psh4XdSuqmMeta } from "/frontend/pages/[identifier]-app/[[slug]].vue?macro=true";
import { default as index3SuYMqdikKMeta } from "/frontend/pages/[identifier]-app/index.vue?macro=true";
import { default as advertiser_45disclosurejsNPx8xUeVMeta } from "/frontend/pages/advertiser-disclosure.vue?macro=true";
import { default as _questionSqJH9444ysMeta } from "/frontend/pages/basic-info-new/_question.vue?macro=true";
import { default as index7pZ3iExbt9Meta } from "/frontend/pages/basic-info-new/index.vue?macro=true";
import { default as basic_45info_45newHoULjU69jtMeta } from "/frontend/pages/basic-info-new.vue?macro=true";
import { default as basic_45infoDzcr9nwUJeMeta } from "/frontend/pages/basic-info.vue?macro=true";
import { default as business_45info_452ye45FvV6ZCMeta } from "/frontend/pages/business-info/business-info-2.vue?macro=true";
import { default as business_45info_453UqneT3WhnGMeta } from "/frontend/pages/business-info/business-info-3.vue?macro=true";
import { default as indexDtPqBtQkypMeta } from "/frontend/pages/business-info/index.vue?macro=true";
import { default as business_45infodkOlzTtbLRMeta } from "/frontend/pages/business-info.vue?macro=true";
import { default as cookie_45testcfdhiCsSdRMeta } from "/frontend/pages/cookie-test.vue?macro=true";
import { default as credit_45card_45widgetfK0U7yA4rtMeta } from "/frontend/pages/credit-card-widget.vue?macro=true";
import { default as accountsgsRheWhf0nMeta } from "/frontend/pages/documents/accounts.vue?macro=true";
import { default as indexh0uHHDGrcXMeta } from "/frontend/pages/documents/index.vue?macro=true";
import { default as manualpXUaRzPlVGMeta } from "/frontend/pages/documents/manual.vue?macro=true";
import { default as resultsJ9KacGQTnxMeta } from "/frontend/pages/documents/results.vue?macro=true";
import { default as statement_45errorQbRqjBCGhsMeta } from "/frontend/pages/documents/statement-error.vue?macro=true";
import { default as sync_45errorjFh9EXdvwbMeta } from "/frontend/pages/documents/sync-error.vue?macro=true";
import { default as syncnpIKBVbFQ2Meta } from "/frontend/pages/documents/sync.vue?macro=true";
import { default as documentsxY6dxfn9neMeta } from "/frontend/pages/documents.vue?macro=true";
import { default as _91error_93dvDgwrqXJrMeta } from "/frontend/pages/embedded/[error].vue?macro=true";
import { default as _91_91slug_93_93kx2aqOEdqGMeta } from "/frontend/pages/embedded/[identifier]-app/[[slug]].vue?macro=true";
import { default as indexvKmLaHTYoyMeta } from "/frontend/pages/embedded/[identifier]-app/index.vue?macro=true";
import { default as accountsEkSb1nVWz6Meta } from "/frontend/pages/embedded/documents/accounts.vue?macro=true";
import { default as indexdD0CTBRNpPMeta } from "/frontend/pages/embedded/documents/index.vue?macro=true";
import { default as manualpKHG85MNwbMeta } from "/frontend/pages/embedded/documents/manual.vue?macro=true";
import { default as resultshQLsm0iXzWMeta } from "/frontend/pages/embedded/documents/results.vue?macro=true";
import { default as statement_45errorSkNtKkB1hQMeta } from "/frontend/pages/embedded/documents/statement-error.vue?macro=true";
import { default as sync_45error9qRnqZAvlkMeta } from "/frontend/pages/embedded/documents/sync-error.vue?macro=true";
import { default as synckoyPDKpwQ9Meta } from "/frontend/pages/embedded/documents/sync.vue?macro=true";
import { default as documentsUQeA57NRfBMeta } from "/frontend/pages/embedded/documents.vue?macro=true";
import { default as indexchSby0TNSqMeta } from "/frontend/pages/embedded/index.vue?macro=true";
import { default as accountsAFWPCBFbYoMeta } from "/frontend/pages/embedded/portal/accounts.vue?macro=true";
import { default as dashboardeh4LK1QW7WMeta } from "/frontend/pages/embedded/portal/dashboard.vue?macro=true";
import { default as documentsHNhBpJpOPYMeta } from "/frontend/pages/embedded/portal/documents.vue?macro=true";
import { default as indexDmmXcsu9TfMeta } from "/frontend/pages/embedded/portal/index.vue?macro=true";
import { default as _91optionId_93NzJbn9QHvwMeta } from "/frontend/pages/embedded/portal/options/[optionId].vue?macro=true";
import { default as resultsNwZBoLUC6GMeta } from "/frontend/pages/embedded/portal/results.vue?macro=true";
import { default as statement_45errorwwGlXAmkTyMeta } from "/frontend/pages/embedded/portal/statement-error.vue?macro=true";
import { default as sync_45errorQU1sEo7jEoMeta } from "/frontend/pages/embedded/portal/sync-error.vue?macro=true";
import { default as syncmOoHkuBdlrMeta } from "/frontend/pages/embedded/portal/sync.vue?macro=true";
import { default as portalRSWM0J4ABqMeta } from "/frontend/pages/embedded/portal.vue?macro=true";
import { default as submitSZwfFSA2H8Meta } from "/frontend/pages/embedded/submit.vue?macro=true";
import { default as indexHkHAubw5j8Meta } from "/frontend/pages/funding-needs/index.vue?macro=true";
import { default as healthcheckbv1LGjf6NUMeta } from "/frontend/pages/healthcheck.vue?macro=true";
import { default as _91component_93qJuBUC5IiXMeta } from "/frontend/pages/identity/[component].vue?macro=true";
import { default as indexoM3zph1x0uMeta } from "/frontend/pages/identity/index.vue?macro=true";
import { default as indexJfWOMM8EtJMeta } from "/frontend/pages/intelligent-lending/index.vue?macro=true";
import { default as dashboardSeKN9pUciRMeta } from "/frontend/pages/intelligent-lending/portal/dashboard.vue?macro=true";
import { default as documentsIzMRM2gVbsMeta } from "/frontend/pages/intelligent-lending/portal/documents.vue?macro=true";
import { default as indexbkxclBPk6qMeta } from "/frontend/pages/intelligent-lending/portal/index.vue?macro=true";
import { default as _91optionId_93bbBB8pjVugMeta } from "/frontend/pages/intelligent-lending/portal/options/[optionId].vue?macro=true";
import { default as portalhqQ0UlkrPyMeta } from "/frontend/pages/intelligent-lending/portal.vue?macro=true";
import { default as login_45errorPBF9SWGCCQMeta } from "/frontend/pages/login-error.vue?macro=true";
import { default as login8M9opu3hOuMeta } from "/frontend/pages/login.vue?macro=true";
import { default as multiselect9a3Y25LZ1fMeta } from "/frontend/pages/multiselect.vue?macro=true";
import { default as newpassWcC54p5HkoMeta } from "/frontend/pages/newpass.vue?macro=true";
import { default as indexAn57psqaiqMeta } from "/frontend/pages/owner-info/index.vue?macro=true";
import { default as owner_45info_452tSbNxPOGknMeta } from "/frontend/pages/owner-info/owner-info-2.vue?macro=true";
import { default as owner_45info_453Kn831IpHJtMeta } from "/frontend/pages/owner-info/owner-info-3.vue?macro=true";
import { default as owner_45info_454hTfGvm5Y3vMeta } from "/frontend/pages/owner-info/owner-info-4.vue?macro=true";
import { default as owner_45info2KYri6P3WaMeta } from "/frontend/pages/owner-info.vue?macro=true";
import { default as adjust_45offer0tKAHoVh63Meta } from "/frontend/pages/portal/adjust-offer.vue?macro=true";
import { default as business_45resourcesYIhoRNbZsVMeta } from "/frontend/pages/portal/business-resources.vue?macro=true";
import { default as closingXm5dRSFiQsMeta } from "/frontend/pages/portal/closing.vue?macro=true";
import { default as credit_45cardsq6uVui8cDqMeta } from "/frontend/pages/portal/credit-cards.vue?macro=true";
import { default as DnqCta5HrT2kA7GgMeta } from "/frontend/pages/portal/DnqCta.vue?macro=true";
import { default as documentsdPqoFiu7CmMeta } from "/frontend/pages/portal/documents.vue?macro=true";
import { default as explore_45other_45productsdpTSfVCcVpMeta } from "/frontend/pages/portal/explore-other-products.vue?macro=true";
import { default as indexF1HJUHW20QMeta } from "/frontend/pages/portal/index.vue?macro=true";
import { default as _91optionId_93RXAhEwNlzrMeta } from "/frontend/pages/portal/loan-offers/[optionId].vue?macro=true";
import { default as indexrzOCBRgoHfMeta } from "/frontend/pages/portal/loan-offers/index.vue?macro=true";
import { default as offers_452_45confirmationnGGKyc9fc0Meta } from "/frontend/pages/portal/offers-2-confirmation.vue?macro=true";
import { default as _91optionId_93xtO4MvL4N2Meta } from "/frontend/pages/portal/offers/[optionId].vue?macro=true";
import { default as indexHpuvqc395kMeta } from "/frontend/pages/portal/offers/index.vue?macro=true";
import { default as _91optionId_93fny5fHinCkMeta } from "/frontend/pages/portal/options/[optionId].vue?macro=true";
import { default as indexGvxEKUZqDCMeta } from "/frontend/pages/portal/options/index.vue?macro=true";
import { default as settings5sIbnpQoHEMeta } from "/frontend/pages/portal/settings.vue?macro=true";
import { default as sunrise_45appPIozIqG1FNMeta } from "/frontend/pages/portal/sunrise-app.vue?macro=true";
import { default as portalR1NlEqQJ5wMeta } from "/frontend/pages/portal.vue?macro=true";
import { default as redirect_45from_45lendio63iIIqTkwVMeta } from "/frontend/pages/redirect-from-lendio.vue?macro=true";
import { default as _91rep_93i3HhxNXpUNMeta } from "/frontend/pages/referral/[rep].vue?macro=true";
import { default as thank_45youZv2ZsJW0QBMeta } from "/frontend/pages/referral/thank-you.vue?macro=true";
import { default as business_45infog69EM4f4MfMeta } from "/frontend/pages/renewal/business-info.vue?macro=true";
import { default as indexOF4KfvoBkjMeta } from "/frontend/pages/renewal/credit-check/index.vue?macro=true";
import { default as review86rwEbeEUCMeta } from "/frontend/pages/renewal/credit-check/review.vue?macro=true";
import { default as indexwuwXaQUxLKMeta } from "/frontend/pages/renewal/documents/index.vue?macro=true";
import { default as manualBKkdQ364iJMeta } from "/frontend/pages/renewal/documents/manual.vue?macro=true";
import { default as index54uuNy4VEFMeta } from "/frontend/pages/renewal/index.vue?macro=true";
import { default as new_45loan_45requirementsWGOaig5S9hMeta } from "/frontend/pages/renewal/new-loan-requirements.vue?macro=true";
import { default as submit3I3Js5gsuuMeta } from "/frontend/pages/renewal/submit.vue?macro=true";
import { default as resetu6sanXCFsXMeta } from "/frontend/pages/reset.vue?macro=true";
import { default as resume_45appH984Kab6AOMeta } from "/frontend/pages/resume-app.vue?macro=true";
import { default as rollbarGJN2plsEGFMeta } from "/frontend/pages/rollbar.vue?macro=true";
import { default as indexMQq7rtVUSrMeta } from "/frontend/pages/sba-complete/index.vue?macro=true";
import { default as documentsztADxtGTBZMeta } from "/frontend/pages/sba-complete/portal/documents.vue?macro=true";
import { default as indexZHNPdw7d0ZMeta } from "/frontend/pages/sba-complete/portal/index.vue?macro=true";
import { default as balance_45sheet1S6lYN2vmqMeta } from "/frontend/pages/sba/balance-sheet.vue?macro=true";
import { default as debt_45schedule_45uploadAlfTOxkjgwMeta } from "/frontend/pages/sba/debt-schedule-upload.vue?macro=true";
import { default as debt_45schedule9mdCCkYCK2Meta } from "/frontend/pages/sba/debt-schedule.vue?macro=true";
import { default as profit_45and_45lossDyKr3vKSKfMeta } from "/frontend/pages/sba/profit-and-loss.vue?macro=true";
import { default as tax_45returnsotxjqeb9AhMeta } from "/frontend/pages/sba/tax-returns.vue?macro=true";
import { default as submitBODkFOXSPpMeta } from "/frontend/pages/submit.vue?macro=true";
import { default as tokenBO4C5yC0yNMeta } from "/frontend/pages/token.vue?macro=true";
import { default as under_45reviewmPSkg1xwVHMeta } from "/frontend/pages/under-review.vue?macro=true";
import { default as indexv41VKvYPDOMeta } from "/frontend/pages/updated-info/index.vue?macro=true";
import { default as updated_45info7LsO9mvLTiMeta } from "/frontend/pages/updated-info.vue?macro=true";
import { default as finicityvTSVc8gjY2Meta } from "/frontend/pages/upload/finicity.vue?macro=true";
import { default as indexvZohKRy7JmMeta } from "/frontend/pages/upload/index.vue?macro=true";
import { default as manualz1eH6QsiToMeta } from "/frontend/pages/upload/manual.vue?macro=true";
import { default as requestsTHo3oqd24tMeta } from "/frontend/pages/upload/requests.vue?macro=true";
import { default as waypoint_450B1SCUtwCcYMeta } from "/frontend/pages/waypoint-0.vue?macro=true";
import { default as waypoint_451v7Lt1D1z87Meta } from "/frontend/pages/waypoint-1.vue?macro=true";
import { default as basic_45infoDQJXFbq5CEMeta } from "~/pages/basic-info.vue?macro=true";
import { default as indexp05jsjiaBTMeta } from "~/pages/owner-info/index.vue?macro=true";
import { default as owner_45info_452EvTXma01J1Meta } from "~/pages/owner-info/owner-info-2.vue?macro=true";
import { default as owner_45info_4535fmFXPdRD4Meta } from "~/pages/owner-info/owner-info-3.vue?macro=true";
import { default as owner_45infocX1MkwIYvpMeta } from "~/pages/owner-info.vue?macro=true";
import { default as indexnWue2NkKc8Meta } from "~/pages/business-info/index.vue?macro=true";
import { default as business_45info_452pixnYywCPkMeta } from "~/pages/business-info/business-info-2.vue?macro=true";
import { default as business_45info_4537WPrrOBWBiMeta } from "~/pages/business-info/business-info-3.vue?macro=true";
import { default as business_45infoWMSuu4bQfQMeta } from "~/pages/business-info.vue?macro=true";
import { default as indexMOOjPajTGGMeta } from "~/pages/documents/index.vue?macro=true";
import { default as accountsrMx7FFEkSsMeta } from "~/pages/documents/accounts.vue?macro=true";
import { default as manual45bRhVj1d6Meta } from "~/pages/documents/manual.vue?macro=true";
import { default as results7PbqIUioVBMeta } from "~/pages/documents/results.vue?macro=true";
import { default as statement_45errorIi6aDKPvdBMeta } from "~/pages/documents/statement-error.vue?macro=true";
import { default as sync_45errorfspmeCkScfMeta } from "~/pages/documents/sync-error.vue?macro=true";
import { default as syncOcFHWDYP2RMeta } from "~/pages/documents/sync.vue?macro=true";
import { default as documentsSBpLC342UmMeta } from "~/pages/documents.vue?macro=true";
import { default as tax_45returnsglKvLKSewvMeta } from "~/pages/sba/tax-returns.vue?macro=true";
import { default as debt_45scheduleICMfs2VJXSMeta } from "~/pages/sba/debt-schedule.vue?macro=true";
import { default as debt_45schedule_45uploadKdnClmSlK7Meta } from "~/pages/sba/debt-schedule-upload.vue?macro=true";
import { default as profit_45and_45loss6KYt05WFPHMeta } from "~/pages/sba/profit-and-loss.vue?macro=true";
import { default as balance_45sheetcOMazjXBgiMeta } from "~/pages/sba/balance-sheet.vue?macro=true";
import { default as submit7M2ZAdLQ6OMeta } from "~/pages/embedded/submit.vue?macro=true";
export default [
  {
    name: "identifier-app-slug",
    path: "/:identifier()-app/:slug?",
    meta: _91_91slug_93_93Psh4XdSuqmMeta || {},
    component: () => import("/frontend/pages/[identifier]-app/[[slug]].vue")
  },
  {
    name: "identifier-app",
    path: "/:identifier()-app",
    component: () => import("/frontend/pages/[identifier]-app/index.vue")
  },
  {
    name: "advertiser-disclosure",
    path: "/advertiser-disclosure",
    component: () => import("/frontend/pages/advertiser-disclosure.vue")
  },
  {
    name: basic_45info_45newHoULjU69jtMeta?.name,
    path: "/basic-info-new",
    meta: basic_45info_45newHoULjU69jtMeta || {},
    component: () => import("/frontend/pages/basic-info-new.vue"),
    children: [
  {
    name: "basic-info-new-_question",
    path: "_question",
    component: () => import("/frontend/pages/basic-info-new/_question.vue")
  },
  {
    name: "basic-info-new",
    path: "",
    meta: index7pZ3iExbt9Meta || {},
    component: () => import("/frontend/pages/basic-info-new/index.vue")
  }
]
  },
  {
    name: "basic-info",
    path: "/basic-info",
    meta: basic_45infoDzcr9nwUJeMeta || {},
    component: () => import("/frontend/pages/basic-info.vue")
  },
  {
    name: business_45infodkOlzTtbLRMeta?.name,
    path: "/business-info",
    meta: business_45infodkOlzTtbLRMeta || {},
    component: () => import("/frontend/pages/business-info.vue"),
    children: [
  {
    name: "business-info-business-info-2",
    path: "business-info-2",
    meta: business_45info_452ye45FvV6ZCMeta || {},
    component: () => import("/frontend/pages/business-info/business-info-2.vue")
  },
  {
    name: "business-info-business-info-3",
    path: "business-info-3",
    meta: business_45info_453UqneT3WhnGMeta || {},
    component: () => import("/frontend/pages/business-info/business-info-3.vue")
  },
  {
    name: "business-info",
    path: "",
    meta: indexDtPqBtQkypMeta || {},
    component: () => import("/frontend/pages/business-info/index.vue")
  }
]
  },
  {
    name: "cookie-test",
    path: "/cookie-test",
    component: () => import("/frontend/pages/cookie-test.vue")
  },
  {
    name: "credit-card-widget",
    path: "/credit-card-widget",
    meta: credit_45card_45widgetfK0U7yA4rtMeta || {},
    component: () => import("/frontend/pages/credit-card-widget.vue")
  },
  {
    name: documentsxY6dxfn9neMeta?.name,
    path: "/documents",
    meta: documentsxY6dxfn9neMeta || {},
    component: () => import("/frontend/pages/documents.vue"),
    children: [
  {
    name: "documents-accounts",
    path: "accounts",
    meta: accountsgsRheWhf0nMeta || {},
    component: () => import("/frontend/pages/documents/accounts.vue")
  },
  {
    name: "documents",
    path: "",
    meta: indexh0uHHDGrcXMeta || {},
    component: () => import("/frontend/pages/documents/index.vue")
  },
  {
    name: "documents-manual",
    path: "manual",
    component: () => import("/frontend/pages/documents/manual.vue")
  },
  {
    name: "documents-results",
    path: "results",
    meta: resultsJ9KacGQTnxMeta || {},
    component: () => import("/frontend/pages/documents/results.vue")
  },
  {
    name: "documents-statement-error",
    path: "statement-error",
    meta: statement_45errorQbRqjBCGhsMeta || {},
    component: () => import("/frontend/pages/documents/statement-error.vue")
  },
  {
    name: "documents-sync-error",
    path: "sync-error",
    meta: sync_45errorjFh9EXdvwbMeta || {},
    component: () => import("/frontend/pages/documents/sync-error.vue")
  },
  {
    name: "documents-sync",
    path: "sync",
    meta: syncnpIKBVbFQ2Meta || {},
    component: () => import("/frontend/pages/documents/sync.vue")
  }
]
  },
  {
    name: "embedded-error",
    path: "/embedded/:error()",
    meta: _91error_93dvDgwrqXJrMeta || {},
    component: () => import("/frontend/pages/embedded/[error].vue")
  },
  {
    name: "embedded-identifier-app-slug",
    path: "/embedded/:identifier()-app/:slug?",
    meta: _91_91slug_93_93kx2aqOEdqGMeta || {},
    component: () => import("/frontend/pages/embedded/[identifier]-app/[[slug]].vue")
  },
  {
    name: "embedded-identifier-app",
    path: "/embedded/:identifier()-app",
    component: () => import("/frontend/pages/embedded/[identifier]-app/index.vue")
  },
  {
    name: documentsUQeA57NRfBMeta?.name,
    path: "/embedded/documents",
    meta: documentsUQeA57NRfBMeta || {},
    component: () => import("/frontend/pages/embedded/documents.vue"),
    children: [
  {
    name: "embedded-documents-accounts",
    path: "accounts",
    component: () => import("/frontend/pages/embedded/documents/accounts.vue")
  },
  {
    name: "embedded-documents",
    path: "",
    component: () => import("/frontend/pages/embedded/documents/index.vue")
  },
  {
    name: "embedded-documents-manual",
    path: "manual",
    component: () => import("/frontend/pages/embedded/documents/manual.vue")
  },
  {
    name: "embedded-documents-results",
    path: "results",
    component: () => import("/frontend/pages/embedded/documents/results.vue")
  },
  {
    name: "embedded-documents-statement-error",
    path: "statement-error",
    component: () => import("/frontend/pages/embedded/documents/statement-error.vue")
  },
  {
    name: "embedded-documents-sync-error",
    path: "sync-error",
    component: () => import("/frontend/pages/embedded/documents/sync-error.vue")
  },
  {
    name: "embedded-documents-sync",
    path: "sync",
    component: () => import("/frontend/pages/embedded/documents/sync.vue")
  }
]
  },
  {
    name: "embedded",
    path: "/embedded",
    meta: indexchSby0TNSqMeta || {},
    component: () => import("/frontend/pages/embedded/index.vue")
  },
  {
    name: portalRSWM0J4ABqMeta?.name,
    path: "/embedded/portal",
    meta: portalRSWM0J4ABqMeta || {},
    component: () => import("/frontend/pages/embedded/portal.vue"),
    children: [
  {
    name: "embedded-portal-accounts",
    path: "accounts",
    component: () => import("/frontend/pages/embedded/portal/accounts.vue")
  },
  {
    name: "embedded-portal-dashboard",
    path: "dashboard",
    component: () => import("/frontend/pages/embedded/portal/dashboard.vue")
  },
  {
    name: "embedded-portal-documents",
    path: "documents",
    component: () => import("/frontend/pages/embedded/portal/documents.vue")
  },
  {
    name: "embedded-portal",
    path: "",
    component: () => import("/frontend/pages/embedded/portal/index.vue")
  },
  {
    name: "embedded-portal-options-optionId",
    path: "options/:optionId()",
    component: () => import("/frontend/pages/embedded/portal/options/[optionId].vue")
  },
  {
    name: "embedded-portal-results",
    path: "results",
    component: () => import("/frontend/pages/embedded/portal/results.vue")
  },
  {
    name: "embedded-portal-statement-error",
    path: "statement-error",
    component: () => import("/frontend/pages/embedded/portal/statement-error.vue")
  },
  {
    name: "embedded-portal-sync-error",
    path: "sync-error",
    component: () => import("/frontend/pages/embedded/portal/sync-error.vue")
  },
  {
    name: "embedded-portal-sync",
    path: "sync",
    component: () => import("/frontend/pages/embedded/portal/sync.vue")
  }
]
  },
  {
    name: "embedded-submit",
    path: "/embedded/submit",
    meta: submitSZwfFSA2H8Meta || {},
    component: () => import("/frontend/pages/embedded/submit.vue")
  },
  {
    name: "funding-needs",
    path: "/funding-needs",
    meta: indexHkHAubw5j8Meta || {},
    component: () => import("/frontend/pages/funding-needs/index.vue")
  },
  {
    name: "Healthcheck",
    path: "/healthcheck",
    meta: healthcheckbv1LGjf6NUMeta || {},
    component: () => import("/frontend/pages/healthcheck.vue")
  },
  {
    name: "identity-component",
    path: "/identity/:component()",
    meta: _91component_93qJuBUC5IiXMeta || {},
    component: () => import("/frontend/pages/identity/[component].vue")
  },
  {
    name: "identity",
    path: "/identity",
    meta: indexoM3zph1x0uMeta || {},
    component: () => import("/frontend/pages/identity/index.vue")
  },
  {
    name: "intelligent-lending",
    path: "/intelligent-lending",
    meta: indexJfWOMM8EtJMeta || {},
    component: () => import("/frontend/pages/intelligent-lending/index.vue")
  },
  {
    name: portalhqQ0UlkrPyMeta?.name,
    path: "/intelligent-lending/portal",
    meta: portalhqQ0UlkrPyMeta || {},
    component: () => import("/frontend/pages/intelligent-lending/portal.vue"),
    children: [
  {
    name: "intelligent-lending-portal-dashboard",
    path: "dashboard",
    meta: dashboardSeKN9pUciRMeta || {},
    component: () => import("/frontend/pages/intelligent-lending/portal/dashboard.vue")
  },
  {
    name: "intelligent-lending-portal-documents",
    path: "documents",
    component: () => import("/frontend/pages/intelligent-lending/portal/documents.vue")
  },
  {
    name: "intelligent-lending-portal",
    path: "",
    component: () => import("/frontend/pages/intelligent-lending/portal/index.vue")
  },
  {
    name: "intelligent-lending-portal-options-optionId",
    path: "options/:optionId()",
    component: () => import("/frontend/pages/intelligent-lending/portal/options/[optionId].vue")
  }
]
  },
  {
    name: "login-error",
    path: "/login-error",
    meta: login_45errorPBF9SWGCCQMeta || {},
    component: () => import("/frontend/pages/login-error.vue")
  },
  {
    name: "login",
    path: "/login",
    meta: login8M9opu3hOuMeta || {},
    component: () => import("/frontend/pages/login.vue")
  },
  {
    name: "multiselect",
    path: "/multiselect",
    component: () => import("/frontend/pages/multiselect.vue")
  },
  {
    name: "newpass",
    path: "/newpass",
    meta: newpassWcC54p5HkoMeta || {},
    component: () => import("/frontend/pages/newpass.vue")
  },
  {
    name: owner_45info2KYri6P3WaMeta?.name,
    path: "/owner-info",
    component: () => import("/frontend/pages/owner-info.vue"),
    children: [
  {
    name: "owner-info",
    path: "",
    meta: indexAn57psqaiqMeta || {},
    component: () => import("/frontend/pages/owner-info/index.vue")
  },
  {
    name: "owner-info-owner-info-2",
    path: "owner-info-2",
    meta: owner_45info_452tSbNxPOGknMeta || {},
    component: () => import("/frontend/pages/owner-info/owner-info-2.vue")
  },
  {
    name: "owner-info-owner-info-3",
    path: "owner-info-3",
    meta: owner_45info_453Kn831IpHJtMeta || {},
    component: () => import("/frontend/pages/owner-info/owner-info-3.vue")
  },
  {
    name: "owner-info-owner-info-4",
    path: "owner-info-4",
    meta: owner_45info_454hTfGvm5Y3vMeta || {},
    component: () => import("/frontend/pages/owner-info/owner-info-4.vue")
  }
]
  },
  {
    name: portalR1NlEqQJ5wMeta?.name,
    path: "/portal",
    meta: portalR1NlEqQJ5wMeta || {},
    component: () => import("/frontend/pages/portal.vue"),
    children: [
  {
    name: "Offers",
    path: "adjust-offer",
    meta: adjust_45offer0tKAHoVh63Meta || {},
    component: () => import("/frontend/pages/portal/adjust-offer.vue")
  },
  {
    name: "portal-business-resources",
    path: "business-resources",
    component: () => import("/frontend/pages/portal/business-resources.vue")
  },
  {
    name: "portal-closing",
    path: "closing",
    component: () => import("/frontend/pages/portal/closing.vue")
  },
  {
    name: "portal-credit-cards",
    path: "credit-cards",
    component: () => import("/frontend/pages/portal/credit-cards.vue")
  },
  {
    name: "portal-DnqCta",
    path: "DnqCta",
    component: () => import("/frontend/pages/portal/DnqCta.vue")
  },
  {
    name: "portal-documents",
    path: "documents",
    component: () => import("/frontend/pages/portal/documents.vue")
  },
  {
    name: "portal-explore-other-products",
    path: "explore-other-products",
    meta: explore_45other_45productsdpTSfVCcVpMeta || {},
    component: () => import("/frontend/pages/portal/explore-other-products.vue")
  },
  {
    name: "portal",
    path: "",
    component: () => import("/frontend/pages/portal/index.vue")
  },
  {
    name: "portal-loan-offers-optionId",
    path: "loan-offers/:optionId()",
    meta: _91optionId_93RXAhEwNlzrMeta || {},
    component: () => import("/frontend/pages/portal/loan-offers/[optionId].vue")
  },
  {
    name: "portal-loan-offers",
    path: "loan-offers",
    meta: indexrzOCBRgoHfMeta || {},
    component: () => import("/frontend/pages/portal/loan-offers/index.vue")
  },
  {
    name: "portal-offers-2-confirmation",
    path: "offers-2-confirmation",
    component: () => import("/frontend/pages/portal/offers-2-confirmation.vue")
  },
  {
    name: "portal-offers-optionId",
    path: "offers/:optionId()",
    meta: _91optionId_93xtO4MvL4N2Meta || {},
    component: () => import("/frontend/pages/portal/offers/[optionId].vue")
  },
  {
    name: "Offers",
    path: "offers",
    meta: indexHpuvqc395kMeta || {},
    component: () => import("/frontend/pages/portal/offers/index.vue")
  },
  {
    name: "portal-options-optionId",
    path: "options/:optionId()",
    component: () => import("/frontend/pages/portal/options/[optionId].vue")
  },
  {
    name: "portal-options",
    path: "options",
    component: () => import("/frontend/pages/portal/options/index.vue")
  },
  {
    name: "portal-settings",
    path: "settings",
    component: () => import("/frontend/pages/portal/settings.vue")
  },
  {
    name: "portal-sunrise-app",
    path: "sunrise-app",
    component: () => import("/frontend/pages/portal/sunrise-app.vue")
  }
]
  },
  {
    name: "redirect-from-lendio",
    path: "/redirect-from-lendio",
    component: () => import("/frontend/pages/redirect-from-lendio.vue")
  },
  {
    name: "referral-rep",
    path: "/referral/:rep()",
    meta: _91rep_93i3HhxNXpUNMeta || {},
    component: () => import("/frontend/pages/referral/[rep].vue")
  },
  {
    name: "referral-thank-you",
    path: "/referral/thank-you",
    component: () => import("/frontend/pages/referral/thank-you.vue")
  },
  {
    name: "renewal-business-info",
    path: "/renewal/business-info",
    meta: business_45infog69EM4f4MfMeta || {},
    component: () => import("/frontend/pages/renewal/business-info.vue")
  },
  {
    name: "renewal-credit-check",
    path: "/renewal/credit-check",
    meta: indexOF4KfvoBkjMeta || {},
    component: () => import("/frontend/pages/renewal/credit-check/index.vue")
  },
  {
    name: "renewal-credit-check-review",
    path: "/renewal/credit-check/review",
    meta: review86rwEbeEUCMeta || {},
    component: () => import("/frontend/pages/renewal/credit-check/review.vue")
  },
  {
    name: "renewal-documents",
    path: "/renewal/documents",
    meta: indexwuwXaQUxLKMeta || {},
    component: () => import("/frontend/pages/renewal/documents/index.vue")
  },
  {
    name: "renewal-documents-manual",
    path: "/renewal/documents/manual",
    meta: manualBKkdQ364iJMeta || {},
    component: () => import("/frontend/pages/renewal/documents/manual.vue")
  },
  {
    name: "renewal",
    path: "/renewal",
    meta: index54uuNy4VEFMeta || {},
    component: () => import("/frontend/pages/renewal/index.vue")
  },
  {
    name: "renewal-new-loan-requirements",
    path: "/renewal/new-loan-requirements",
    meta: new_45loan_45requirementsWGOaig5S9hMeta || {},
    component: () => import("/frontend/pages/renewal/new-loan-requirements.vue")
  },
  {
    name: "renewal-submit",
    path: "/renewal/submit",
    meta: submit3I3Js5gsuuMeta || {},
    component: () => import("/frontend/pages/renewal/submit.vue")
  },
  {
    name: "reset",
    path: "/reset",
    meta: resetu6sanXCFsXMeta || {},
    component: () => import("/frontend/pages/reset.vue")
  },
  {
    name: "resume-app",
    path: "/resume-app",
    meta: resume_45appH984Kab6AOMeta || {},
    component: () => import("/frontend/pages/resume-app.vue")
  },
  {
    name: "rollbar",
    path: "/rollbar",
    component: () => import("/frontend/pages/rollbar.vue")
  },
  {
    name: "sba-complete",
    path: "/sba-complete",
    meta: indexMQq7rtVUSrMeta || {},
    component: () => import("/frontend/pages/sba-complete/index.vue")
  },
  {
    name: "sba-complete-portal-documents",
    path: "/sba-complete/portal/documents",
    meta: documentsztADxtGTBZMeta || {},
    component: () => import("/frontend/pages/sba-complete/portal/documents.vue")
  },
  {
    name: "sba-complete-portal",
    path: "/sba-complete/portal",
    meta: indexZHNPdw7d0ZMeta || {},
    component: () => import("/frontend/pages/sba-complete/portal/index.vue")
  },
  {
    name: "sba-balance-sheet",
    path: "/sba/balance-sheet",
    meta: balance_45sheet1S6lYN2vmqMeta || {},
    component: () => import("/frontend/pages/sba/balance-sheet.vue")
  },
  {
    name: "sba-debt-schedule-upload",
    path: "/sba/debt-schedule-upload",
    meta: debt_45schedule_45uploadAlfTOxkjgwMeta || {},
    component: () => import("/frontend/pages/sba/debt-schedule-upload.vue")
  },
  {
    name: "sba-debt-schedule",
    path: "/sba/debt-schedule",
    meta: debt_45schedule9mdCCkYCK2Meta || {},
    component: () => import("/frontend/pages/sba/debt-schedule.vue")
  },
  {
    name: "sba-profit-and-loss",
    path: "/sba/profit-and-loss",
    meta: profit_45and_45lossDyKr3vKSKfMeta || {},
    component: () => import("/frontend/pages/sba/profit-and-loss.vue")
  },
  {
    name: "sba-tax-returns",
    path: "/sba/tax-returns",
    meta: tax_45returnsotxjqeb9AhMeta || {},
    component: () => import("/frontend/pages/sba/tax-returns.vue")
  },
  {
    name: "submit",
    path: "/submit",
    meta: submitBODkFOXSPpMeta || {},
    component: () => import("/frontend/pages/submit.vue")
  },
  {
    name: "token",
    path: "/token",
    meta: tokenBO4C5yC0yNMeta || {},
    component: () => import("/frontend/pages/token.vue")
  },
  {
    name: "under-review",
    path: "/under-review",
    meta: under_45reviewmPSkg1xwVHMeta || {},
    component: () => import("/frontend/pages/under-review.vue")
  },
  {
    name: updated_45info7LsO9mvLTiMeta?.name,
    path: "/updated-info",
    component: () => import("/frontend/pages/updated-info.vue"),
    children: [
  {
    name: "updated-info",
    path: "",
    meta: indexv41VKvYPDOMeta || {},
    component: () => import("/frontend/pages/updated-info/index.vue")
  }
]
  },
  {
    name: "upload-finicity",
    path: "/upload/finicity",
    component: () => import("/frontend/pages/upload/finicity.vue")
  },
  {
    name: "upload",
    path: "/upload",
    component: () => import("/frontend/pages/upload/index.vue")
  },
  {
    name: "upload-manual",
    path: "/upload/manual",
    component: () => import("/frontend/pages/upload/manual.vue")
  },
  {
    name: "upload-requests",
    path: "/upload/requests",
    component: () => import("/frontend/pages/upload/requests.vue")
  },
  {
    name: "waypoint-0",
    path: "/waypoint-0",
    meta: waypoint_450B1SCUtwCcYMeta || {},
    component: () => import("/frontend/pages/waypoint-0.vue")
  },
  {
    name: "waypoint-1",
    path: "/waypoint-1",
    meta: waypoint_451v7Lt1D1z87Meta || {},
    component: () => import("/frontend/pages/waypoint-1.vue")
  },
  {
    name: "sba-complete-basic-info",
    path: "/sba-complete/basic-info",
    meta: basic_45infoDQJXFbq5CEMeta || {},
    component: () => import("~/pages/basic-info.vue")
  },
  {
    name: owner_45infocX1MkwIYvpMeta?.name,
    path: "/sba-complete/owner-info",
    component: () => import("~/pages/owner-info.vue"),
    children: [
  {
    name: "sba-complete-owner-info",
    path: "",
    meta: indexp05jsjiaBTMeta || {},
    component: () => import("~/pages/owner-info/index.vue")
  },
  {
    name: "sba-complete-owner-info-2",
    path: "owner-info-2",
    meta: owner_45info_452EvTXma01J1Meta || {},
    component: () => import("~/pages/owner-info/owner-info-2.vue")
  },
  {
    name: "sba-complete-owner-info-3",
    path: "owner-info-3",
    meta: owner_45info_4535fmFXPdRD4Meta || {},
    component: () => import("~/pages/owner-info/owner-info-3.vue")
  }
]
  },
  {
    name: business_45infoWMSuu4bQfQMeta?.name,
    path: "/sba-complete/business-info",
    meta: business_45infoWMSuu4bQfQMeta || {},
    component: () => import("~/pages/business-info.vue"),
    children: [
  {
    name: "sba-complete-business-info-1",
    path: "",
    meta: indexnWue2NkKc8Meta || {},
    component: () => import("~/pages/business-info/index.vue")
  },
  {
    name: "sba-complete-business-info-2",
    path: "business-info-2",
    meta: business_45info_452pixnYywCPkMeta || {},
    component: () => import("~/pages/business-info/business-info-2.vue")
  },
  {
    name: "sba-complete-business-info-3",
    path: "business-info-3",
    meta: business_45info_4537WPrrOBWBiMeta || {},
    component: () => import("~/pages/business-info/business-info-3.vue")
  }
]
  },
  {
    name: documentsSBpLC342UmMeta?.name,
    path: "/sba-complete/documents",
    meta: documentsSBpLC342UmMeta || {},
    component: () => import("~/pages/documents.vue"),
    children: [
  {
    name: "sba-complete-bank-statements",
    path: "",
    meta: indexMOOjPajTGGMeta || {},
    component: () => import("~/pages/documents/index.vue")
  },
  {
    name: "sba-complete-bank-statements-accounts",
    path: "accounts",
    meta: accountsrMx7FFEkSsMeta || {},
    component: () => import("~/pages/documents/accounts.vue")
  },
  {
    name: "sba-complete-bank-statements-manual",
    path: "manual",
    component: () => import("~/pages/documents/manual.vue")
  },
  {
    name: "sba-complete-bank-statements-results",
    path: "results",
    meta: results7PbqIUioVBMeta || {},
    component: () => import("~/pages/documents/results.vue")
  },
  {
    name: "sba-complete-bank-statements-statement-error",
    path: "statement-error",
    meta: statement_45errorIi6aDKPvdBMeta || {},
    component: () => import("~/pages/documents/statement-error.vue")
  },
  {
    name: "sba-complete-bank-statements-sync-error",
    path: "sync-error",
    meta: sync_45errorfspmeCkScfMeta || {},
    component: () => import("~/pages/documents/sync-error.vue")
  },
  {
    name: "sba-complete-bank-statements-sync",
    path: "sync",
    meta: syncOcFHWDYP2RMeta || {},
    component: () => import("~/pages/documents/sync.vue")
  }
]
  },
  {
    name: "sba-complete-tax-returns",
    path: "/sba-complete/tax-returns",
    meta: tax_45returnsglKvLKSewvMeta || {},
    component: () => import("~/pages/sba/tax-returns.vue")
  },
  {
    name: "sba-complete-debt-schedule",
    path: "/sba-complete/debt-schedule",
    meta: debt_45scheduleICMfs2VJXSMeta || {},
    component: () => import("~/pages/sba/debt-schedule.vue")
  },
  {
    name: "sba-complete-debt-schedule-upload",
    path: "/sba-complete/debt-schedule-upload",
    meta: debt_45schedule_45uploadKdnClmSlK7Meta || {},
    component: () => import("~/pages/sba/debt-schedule-upload.vue")
  },
  {
    name: "sba-complete-profit-and-loss",
    path: "/sba-complete/profit-and-loss",
    meta: profit_45and_45loss6KYt05WFPHMeta || {},
    component: () => import("~/pages/sba/profit-and-loss.vue")
  },
  {
    name: "sba-complete-balance-sheet",
    path: "/sba-complete/balance-sheet",
    meta: balance_45sheetcOMazjXBgiMeta || {},
    component: () => import("~/pages/sba/balance-sheet.vue")
  },
  {
    name: "sba-complete-submit",
    path: "/sba-complete/submit",
    meta: submit7M2ZAdLQ6OMeta || {},
    component: () => import("~/pages/embedded/submit.vue")
  }
]