/** @typedef {import('../store/deals.js').Deal} Deal */

const ACTIVE_APP_DAYS = 30;

function isPolyfillFindLastIndex() {
  return (isPolyfillFindLastIndex.__cachedTypeofCheckResult ??=
    typeof Array.prototype.findLastIndex !== "function");
}

/**
 * @template T
 * @param {T[]} array
 * @param {(T) => boolean} predicate
 */
function findLastIndex(array, predicate) {
  const arrayLength = array.length;
  const finalIndex = arrayLength - 1;
  for (let i = finalIndex, j = 0; i >= j; i--) {
    const arrayElement = array[i];
    const result = predicate(arrayElement);
    if (result) {
      return i;
    }
  }
  return -1
}

/**
 *
 * @param {Deal} deal
 * @returns {'Pending' | 'Option Available' | 'Declined' | 'Accepted' | 'Closing' | 'Processing' | null}
 */
function getLenderSubmissionDealState(deal) {
  switch (deal.status) {
    case "awaitingResponse":
    case "pendingFundingManager":
      return "Pending";
    case "declined":
      return "Not qualified";
    case "offerReceived":
    case "offerToBorrower":
      return "Option Available";
    case "offerDeclined":
      return "Declined";
    case "offerAccepted":
      return "Accepted";
    case "contractRequested":
    case "contractOut":
      return "Closing";
    case "contractIn":
    case "contractSigned":
    case "funding":
      return "Processing";
    default:
      return null;
  }
}

/**
 * @typedef { Object } BorrowerApplication
 * @property { string } BorrowerApplication.created
 * @property { number } id
 */
/**
 * @param {Object} param
 * @param {Deal[]} param.deals
 * @param {BorrowerApplication[]} param.applications
 */
function groupByBorrowerApplication({ deals, applications }) {
  const groupedDeals = {};
  // descending sort without modifying original array.  node 18 does not support toSorted yet
  const sortedDeals = [...deals].sort((a, b) =>
    a.created < b.created ? 1 : -1
  );
  const sortedApps = [...applications].sort((a, b) =>
    a.created < b.created ? 1 : -1
  );
  for (const application of sortedApps) {
    let lastDealToAddIndex;
    const createdDate = application.created;
    const predicate = (deal) => deal.created >= createdDate;
    if (isPolyfillFindLastIndex()) {
      lastDealToAddIndex = findLastIndex(sortedDeals, predicate);
    } else {
      lastDealToAddIndex = sortedDeals.findLastIndex(predicate);
    }

    groupedDeals[application.id] = application;
    if (lastDealToAddIndex > -1) {
      // Add all deals created after the latest application (inclusive)
      groupedDeals[application.id]["deals"] = sortedDeals.splice(
        0,
        lastDealToAddIndex + 1
      );
    }
  }
  return groupedDeals;
}
/** @param {Deal[]} deals
 *  @param {Object[]|null} availableOffers */
function filterLenderSubmittedDeals(deals, availableOffers = null) {
  const dealsWithAvailableOffers = Array.isArray(availableOffers) ? [... new Set(availableOffers.map(offer => offer.dealId))] : null
  const closingStatuses = ['offerAccepted', 'contractRequested', 'contractOut', 'contractIn', 'funding']
  const underwritingStatuses = ['awaitingResponse', 'pendingFundingManager', 'offerReceived', 'offerToBorrower']
  const hasDealsInClosingStage = deals.some((deal) => (
    closingStatuses.includes(deal.status)
    || (underwritingStatuses.includes(deal.status) && deal.acceptedOffer)
  ))

  return deals
    .filter((deal) => (
      ['inactive', 'funded'].includes(deal.stage) === false
      && deal.loanProductOfferType !== 'tier-3'
      && (
        !hasDealsInClosingStage
        || closingStatuses.includes(deal.status)
        || (underwritingStatuses.includes(deal.status) && deal.acceptedOffer)
      )
    ))
    .map((deal) => {
      if (underwritingStatuses.includes(deal.status) && deal.acceptedOffer) {
        deal.submissionState = 'Accepted';
      } else if (deal.status.includes('offerReceived', 'offerToBorrower') && Array.isArray(dealsWithAvailableOffers) && !dealsWithAvailableOffers.includes(deal.id)) {
        deal.submissionState = 'Pending';
      } else {
        deal.submissionState = getLenderSubmissionDealState(deal);
      }
      return deal;
    });
}
/** @param {(Deal & {submissionState: string})[]} lenderSubmittedDeals */
function hasDealInSubmission(lenderSubmittedDeals) {
  return lenderSubmittedDeals.some((deal) => deal.submissionState !== null);
}

export {
  ACTIVE_APP_DAYS,
  groupByBorrowerApplication,
  getLenderSubmissionDealState,
  filterLenderSubmittedDeals,
  hasDealInSubmission,
};
