import env from '~/libs/env'

/**
 * ApplicationConfigService
 *
 * Facilitates api calls for Application Config models.
 *
 * Requires a store passed so it has access to the correct `$axios` in context
 * for any XHR requests. Helps manage the interface of saving answers between
 * local storage and the actual service.
 */
export class ApplicationConfigService {
    constructor($axios) {
      this.$axios = $axios
    }

    /**
     * Returns ApplicationConfig for given ApplicationConfigEntrypoint identifier.
     *
     * @returns ApplicationConfig
     */
    async getByIdentifier({ identifier, borrowerId, tenantId }) {
      const url = borrowerId
        ? `${env('apiUrl')}/application-config/${identifier}/borrower/${borrowerId}`
        : `${env('apiUrl')}/application-config/${identifier}`

      let error
      const response = await this.$axios.get(url, {
          params: { tenantId },
        })
        .catch(err => {``
          error = err
        })

      if (error) {
        return error
      }

      return response?.data?.data
    }

    /**
     * Returns ApplicationConfig for given ApplicationConfigEntrypoint identifier.
     * Only includes data for the NEXT route you're visiting
     *
     * @returns ApplicationConfig
     */
    async getByIdentifierAndSlug({ identifier, slug = '', borrowerId, tenantId, direction }) {

      const url = borrowerId
        ? `${env('apiUrl')}/application-config/borrower/${borrowerId}/identifier/${identifier}/${slug}`
        : `${env('apiUrl')}/application-config/${identifier}/${slug}`

      let error
      const response = await this.$axios.get(url, {
          params: { tenantId, direction },
        })
        .catch(err => {``
          error = err
        })

      if (error) {
        return error
      }

      return response?.data?.data
    }

    /**
     * @param {{[string]: any}} answers
     * @returns {boolean} successful
     */
    async saveAnswers({ answers, borrowerId }) {
      if (!process.client) {
        log.error('Tried to save answers during server-side render', new Error())
        return false
      }

      const { data } = await this.$axios.post(`${env('apiUrl')}/answers/borrower/${borrowerId}`, answers)
        .then(response => response.data)
        .catch(err => {
          if (err && err.response && err.response.status > 401) {
            log.error('Unable to save answers', Object.keys(answers), err)
          }
          return { data: false }
        })

      return data
    }
}

export default {
  ApplicationConfigService
}
