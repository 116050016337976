export default defineNuxtPlugin((nuxtApp) => {
    const isEmbeddedRoute = useRoute().path.startsWith('/embedded')
    nuxtApp.hook('page:finish', () => {
            if (isEmbeddedRoute && window) {
                window.parent.postMessage({
                    source: 'BP',
                    method: 'iframeStylesReady',
                    action: 'iframeStylesReady'
                  }, "*")
            }
      })
})
