export function getOfferState (offer, deal) {
  if (deal.status === 'current') {
    return 'funded'
  }

  if (deal.status === 'contractSigned' || deal.status === 'contractIn' || deal.status === 'funding') {
    return 'contractSigned'
  }

  if (deal.status === 'contractRequested' || deal.status === 'contractOut') {
    return 'contractRequested'
  }

  if (offer.accepted) {
    return 'offerAccepted'
  }

  if (deal.autoSubmitType) {
    if (offer.amount) {
      return 'autoOfferDetails'
    } else {
      return 'autoOfferNoDetails'
    }
  }

  if (offer.publishedToBorrower) {
    return 'offerToBorrower'
  } else {
    return 'offerReceived'
  }
}

export function isOfferValidForContractRequest(offer) {
  if (offer.isOfferOption) {
    return true
  }

  if (offer.loanProductType && offer.loanProductType === 'ach') {
    return offer.factor && offer.paymentFrequency && offer.term
  }

  return offer.paymentFrequency && offer.term
}

export function normalizeOffer(offer, deal) {
  return {
    ...offer,
    loanProductCategoryType: offer.loanProductType,
    loanProductLenderId: deal.lenderId,
    originationFeePercent: offer.originationFee,
    factorRate: offer.factor,
    isStaticOffer: true,
  }
}

export function getOfferKey(offer) {
  let key = ''
  if (offer.isOfferOption) {
    key = 'option'
  } else if (offer.isStaticOffer) {
    key = 'offer'
  } else {
    key = 'approval'
  }
  return `${key}-${offer.id}`
}

export function getAcceptedOffer(deal) {
  if (deal?.acceptedOffer) {
    return deal.acceptedOffer
  }
  const activeOffers = (deal?.offers || []).filter((offer) => {
    return Boolean(offer.accepted) || Boolean(offer.borrowerAcceptedDate);
  }).reverse();

  return activeOffers.length ? activeOffers.at(0) : null;
}

export default {
  getOfferState,
  isOfferValidForContractRequest,
  normalizeOffer,
  getOfferKey,
}
