import { defineStore } from 'pinia'
import { ref, computed } from 'vue'
import { useDealsStore } from '~/store/deals'
import { useAffiliateCustomizationStore } from '~/store/affiliate-customization'
import { useBorrowerStore } from '~/store/borrower'
import { useRootStore } from '~/store/root'

import get from 'lodash/get'
import env from '~/libs/env'

export const useRecommendedOptions = defineStore('recommendedOptions', () => {
  const nuxtApp = useNuxtApp()
  const { $axios } = nuxtApp

  const dealsStore = useDealsStore()
  const affiliateCustomizationStore = useAffiliateCustomizationStore()
  const borrowerStore = useBorrowerStore()
  const rootStore = useRootStore()


/*
  ███████ ████████  █████  ████████ ███████
  ██         ██    ██   ██    ██    ██
  ███████    ██    ███████    ██    █████
       ██    ██    ██   ██    ██    ██
  ███████    ██    ██   ██    ██    ███████
  STATE
*/
  const showInfo = ref(false)
  const recommendedOptionContent = ref([])
  const requestedLoanType = ref(null)
  const recommendedOptionContentRequest = ref(null)
  const recommendedOptionContentAbort = ref(null)

/*
  ██████  ███████ ████████ ████████ ███████ ██████  ███████
 ██       ██         ██       ██    ██      ██   ██ ██
 ██   ███ █████      ██       ██    █████   ██████  ███████
 ██    ██ ██         ██       ██    ██      ██   ██      ██
  ██████  ███████    ██       ██    ███████ ██   ██ ███████
 GETTERS
*/
  const recommendedOption = computed(() => {
    const isMarketplace = !rootStore.isEmbedded && !affiliateCustomizationStore.isIntelligentLendingAffiliate
    if (!isMarketplace && !affiliateCustomizationStore.modernOfferAcceptanceHasRecommendedFlag) {
      return null
    }

    const options = dealsStore.availableOptions
    return options?.[0] ?? null
  })

  const recommendedOptionContentItems = computed(() => {
    return (recommendedOptionContent.value || []).map((item) => {
      const iconsMap = {
        expansion: 'chart-line-up',
        workingCapital: 'money-from-bracket',
        payroll: 'users',
        purchaseABusiness: 'shop',
        equipment: 'screwdriver-wrench',
        startABusiness: 'seedling',
        amount: 'circle-dollar',
        cost: 'badge-percent',
        speed: 'calendar-days',
      }

      return {
        ...item,
        icon: iconsMap[item.key] ?? null,
      }
    })
  })

/*
   █████   ██████ ████████ ██  ██████  ███    ██ ███████
  ██   ██ ██         ██    ██ ██    ██ ████   ██ ██
  ███████ ██         ██    ██ ██    ██ ██ ██  ██ ███████
  ██   ██ ██         ██    ██ ██    ██ ██  ██ ██      ██
  ██   ██  ██████    ██    ██  ██████  ██   ████ ███████
  ACTIONS
  ! - - Actions calling other actions in the same store must use `this.actionName(...)`
  ! - - If we do not use `this.actionName` it will not be properly mockable in tests.
  ! - - Computeds and refs will work fine, and should be called directly though.
*/
  function isRecommended(option) {
    const getKey = (offer) => {
      let key = ''
      if (offer.isOfferOption) {
        key = 'offer-option'
      } else if (offer.isStaticOffer) {
        key = 'offer'
      } else {
        key = 'approval'
      }
      return `${key}:${offer.id}`
    }

    return option && recommendedOption.value && getKey(option) === getKey(recommendedOption.value)
  }

  function setRecommendedModalState(value) {
    showInfo.value = value
  }

  async function getRecommendedOptionContent(force = false) {
    if (!borrowerStore.borrowerId) {
      log.error(
        'recommendedOptions could not be retrieved because of null or undefined borrowerId on rootState',
        {
          borrowerId: borrowerStore.borrowerId,
          borrower: borrowerStore.borrower
        }
      )
      return
    }

    if (!recommendedOption.value) {
      return
    }

    const loanType = recommendedOption.value?.loanProductCategoryType

    if (recommendedOptionContentRequest.value && requestedLoanType.value === loanType) {
      return recommendedOptionContentRequest.value
    }

    if (requestedLoanType.value && requestedLoanType.value === loanType && !force) {
      return
    }

    if (requestedLoanType.value && requestedLoanType.value !== loanType) {
      recommendedOptionContent.value = []
    }

    if (recommendedOptionContentAbort.value) {
      recommendedOptionContentAbort.value.abort()
    }

    if (process.client) {
      recommendedOptionContentAbort.value = new AbortController()
    }

    const requestPromise = $axios
      .get(
        `${env('apiUrl')}/borrower/${
          borrowerStore.borrowerId
        }/recommended-option-content`,
        {
          params: { loanType },
          signal: recommendedOptionContentAbort.value?.signal
        }
      )
    if (process.client) {
      recommendedOptionContentRequest.value = requestPromise
    }
    requestedLoanType.value = loanType

    try {
      const res = await requestPromise
      recommendedOptionContent.value = get(res, 'data.data', [])
    } catch (e) {
      recommendedOptionContent.value = []

      log.error("Unable to get recommended option content", {
        err: e,
        errMessage: e.message,
      });
    } finally {
      recommendedOptionContentRequest.value = null
      recommendedOptionContentAbort.value = null
    }
  }

  return {
    //STATE
    showInfo,
    recommendedOptionContent,
    requestedLoanType,
    recommendedOptionContentRequest,
    recommendedOptionContentAbort,
    //GETTERS
    recommendedOption,
    recommendedOptionContentItems,
    //ACTIONS
    isRecommended,
    setRecommendedModalState,
    getRecommendedOptionContent,
  }
})
