import { embeddedErrorRouting } from '~/libs/embedded'

export default defineNuxtRouteMiddleware(async (to, from) => {
  const nuxtApp = useNuxtApp()
  const { $lendioCookies } = nuxtApp
  const query = to.query

  const isEmbeddedRoute = from.fullPath.includes('/embedded')
  const isEmbeddedCookie = $lendioCookies.get('isEmbedded')
  const isEmbedded = isEmbeddedCookie || isEmbeddedRoute
  if (query && query.sso) {
    const token = $lendioCookies.get('token')
    if (!token) {
      if (isEmbedded) {
        return nuxtApp.runWithContext(() => navigateTo(embeddedErrorRouting(401)))
      } else {
        return nuxtApp.runWithContext(() => navigateTo(`/bp/login?r=${encodeURIComponent(to.fullPath)}`))
      }
    }
  }
})
