import env from '~/libs/env';

export default defineNuxtPlugin(() => {
    let embeddedScript = document.getElementById('lendio-embedded')
    const isEmbeddedRoute = useRoute().path.startsWith('/embedded')
    const fastTrackTestEnabled = env('fastTrackTestEnabled') === 'true' || env('fastTrackTestEnabled') === true
    if (embeddedScript || isEmbeddedRoute || !fastTrackTestEnabled) {
        return
    }

    const apiFlowEnabled = env('embeddedApiFlowEnabled') === 'true' || env('embeddedApiFlowEnabled') === true
    const embeddedPartnerId = apiFlowEnabled ? env('embeddedAffiliateId') : env('embeddedPartnerId')
    const embeddedServiceUrl = env('embeddedServiceUrl')

    let script = document.createElement('script')
    script.id = 'lendio-embedded'
    script.innerHTML = '(function(partnerId, embeddedUrl){(function(l,e,n,d,i,o){var p;i=l[d]=l[d]||{};i._q=i._q||{};i[\'initialize\']=i[\'initialize\']||function(arg){i._q[\'initialize\']=arg};o=document.createElement(n);o.async=!0;o.src=embeddedUrl.concat(\'/static/partners/\').concat(partnerId);p=e.getElementsByTagName(n)[0];p.parentNode.insertBefore(o,p);l.sessionStorage.setItem(\'_lendioPartnerId\', partnerId);})(window, document,\'script\',\'lendio\');})('+ '\''+embeddedPartnerId+'\''+','+'\''+embeddedServiceUrl+'\''+');'
    document.head.appendChild(script)
})
