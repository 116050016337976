/*
  This is working and can be testing on the axios example page.
  To be fully functional the cookie service and root store need to be working.
 */
import get from 'lodash/get'
import env from '~/libs/env'
import { embeddedErrorRouting } from '~/libs/embedded'
import { useRootStore } from '~/store/root'
import { useUserStore } from '~/store/user'

async function handle401 ({ config, error, cookies, userStore, rootStore, route }) {
  const conditionalRouteStrings = ['/account-connection/', 'financial-integration-service', 'user/logout'];

  const isMobile = process.client && cookies.get('isMobile') && window.flutter_inappwebview
  if (isMobile) {
    window.flutter_inappwebview.callHandler('tokenHandler')
    return Promise.reject(error)
  }

  const isEmbeddedCookie = process.client && cookies.get('isEmbedded')
  const isEmbeddedUser = get(rootStore, 'authUser.isEmbedded')
  const isEmbeddedRoute = get(window, 'location.pathname', '').includes('embedded');

  const is401RouteApproved = conditionalRouteStrings
    .some(str => get(error, 'response.config.url').includes(str));

  const isEmbeddedCheck = isEmbeddedUser || isEmbeddedCookie || isEmbeddedRoute
  const errorMessage = get(error, 'response.data.errors[0].message')

  if (isEmbeddedCheck) {
    window.vueRouter.push(embeddedErrorRouting(401))
    return
  }

  // Ignore requests that were sent without an auth header
  const authHeader = get(config, 'headers.Authorization')
  if (!authHeader) {
    return
  }

  // Handle DNC-caused 401s separately
  if (errorMessage !== null && errorMessage == 'User is marked as do not contact') {
    return rootStore.logout({ $router: window.vueRouter, path: 'bp/under-review', afterLoginRedirect: true })

  // Log users out and redirect to login page except for specific excepted routes
  } else if(!is401RouteApproved) {
    return rootStore.logout({ path: route.path })
  }
}

export default defineNuxtPlugin (({ $axios, $pinia, $lendioCookies, $config }) => {
  $pinia.use(() => ({ $axios }))

  let thisConfig = null
  const userStore = useUserStore($pinia)
  const rootStore = useRootStore($pinia)
  const route = useRoute()
  let inProgress401 = false

  // Add auth header to all requests
  $axios.interceptors.request.use((config) => {
    // Don't override auth header if they are explicitly set
    if (config.headers['Authorization']) return config

    const Authorization = userStore.getAuthHeader({ acceptNoAuth: true, silent: false, requestUrl: config?.url ?? null })
    if (Authorization) {
      config.headers['Authorization'] = Authorization
    }

    thisConfig = config
    return config
  })

  // if using identity auth, replace api or bpApi urls with the web gateway equivalent urls
  // if client, replace url with external web gateway url
  // if server, replace url with internal web gateway url and manually set origin and host headers

  const origin = process.server ? useRequestURL()?.origin : window.location.origin;

  $axios.interceptors.request.use((config) => {
    const usingIdentityAuth = $lendioCookies.get('authType') === 'LENDIO_JWT'
    const requestUrl = new URL(config.url)
    const apiUrl = env('apiUrl')
    const bpApiUrl = env('bpApiUrl')
    if (usingIdentityAuth && (requestUrl.origin === apiUrl || requestUrl.origin === bpApiUrl)) {
      if (process.server) {
        config.headers['origin'] = origin;
        config.headers['host'] = config.url.includes(bpApiUrl)
          ? new URL($config.public.bpApiWgUrl).host
          : new URL($config.public.apiWgUrl).host
        config.url = config.url.includes(bpApiUrl)
          ? config.url.replace(bpApiUrl, env('internalWebGatewayUrl'))
          : config.url.replace(apiUrl, env('internalWebGatewayUrl'))
      } else {
        config.url = config.url.includes(bpApiUrl)
          ? config.url.replace(bpApiUrl, env('bpApiWgUrl'))
          : config.url.replace(apiUrl, env('apiWgUrl'))
      }
    }
    thisConfig = config
    return config
  })

  // Handle 401 errors
  $axios.interceptors.response.use((response) => {
    return response
  }, error => {
    // if identity service, just pass on the error
    if (error?.request?.responseURL?.includes(env('identityServiceUrl'))) {
      return Promise.reject(error)
    }

    // Without a response, this means a network error has occurred
    if (!error?.response) {
      log.warning('Axios Network Error: ', error)
    }

    if (env('environment') != 'LIVE') {
      console.log('AXIOS error on', get(error, 'response.request.path'), error.stack)
    }
    const status = get(error, 'response.status')
    const is401 = status === 401

    if (!is401) {
      return Promise.reject(error)
    }

    // only need to handle the first 401
    if (inProgress401) {
      return
    }
    inProgress401 = true

    return handle401({
      config: thisConfig,
      cookies: $lendioCookies,
      error,
      userStore,
      rootStore,
      route
    }).finally(() => {
      inProgress401 = false
    })
  })

  const gatewayHosts = [
    'apiWgUrl',
    'bpApiWgUrl',
    'identityServiceUrl',
  ].map(url => {
    try {
      return new URL(env(url)).host
    } catch (e) {
      return null
    }
  })
  .filter(url => !!url)

  // Queue session extension on successful requests
  $axios.interceptors.response.use((response, ...args) => {
    // Only run on the client side
    if (process.server) {
      return response
    }

    if (!response) {
      return response
    }

    // Ignore the token refresh itself
    if (response.config.url.includes('/auth/token-refresh') || response.config.url.includes('/bp-re-auth')) {
      return response
    }

    // Ignore unauthenticated requests
    if (!response.config.headers['Authorization']) {
      return response
    }

    const host = new URL(response.config.url).host
    if (!gatewayHosts.includes(host)) {
      return response
    }
    userStore.reauth()
    return response
  })
})
