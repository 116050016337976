
import { defineStore } from 'pinia'
import { useAffiliateCustomizationStore } from './affiliate-customization'
import { useAppAnalyticsStore } from '~/store/app-analytics'
import { useRootStore } from '~/store/root'
import AnalyticsService from '~/libs/analytics-service'
import env from '~/libs/env'
import get from 'lodash/get.js'
import throttle from 'lodash/throttle'

export const useUserStore = defineStore('user', () => {
  const nuxtApp = useNuxtApp()
  const { $axios, $lendioCookies, $pinia } = nuxtApp
  const appAnalyticsStore = useAppAnalyticsStore()
  const affiliateCustomizationStore = useAffiliateCustomizationStore()
  const rootStore = useRootStore()

  /*
  ███████ ████████  █████  ████████ ███████
  ██         ██    ██   ██    ██    ██
  ███████    ██    ███████    ██    █████
       ██    ██    ██   ██    ██    ██
  ███████    ██    ██   ██    ██    ███████
  STATE
*/
  const accessToken = ref(null)
  const signInError = ref(false)
  const freshLogin = ref(null)
  const borrowers = ref(null)

  /*
   ██████  ███████ ████████ ████████ ███████ ██████  ███████
  ██       ██         ██       ██    ██      ██   ██ ██
  ██   ███ █████      ██       ██    █████   ██████  ███████
  ██    ██ ██         ██       ██    ██      ██   ██      ██
   ██████  ███████    ██       ██    ███████ ██   ██ ███████
  GETTERS
*/

  const hasOptimusUser = computed(() => {
    return Boolean(get(rootStore.authUser, 'id'))
  })

  const hasMultipleBorrowers = computed(() => {
    return borrowers.value ? borrowers.value.length > 1 : false
  })

  /*
   █████   ██████ ████████ ██  ██████  ███    ██ ███████
  ██   ██ ██         ██    ██ ██    ██ ████   ██ ██
  ███████ ██         ██    ██ ██    ██ ██ ██  ██ ███████
  ██   ██ ██         ██    ██ ██    ██ ██  ██ ██      ██
  ██   ██  ██████    ██    ██  ██████  ██   ████ ███████
  ACTIONS
  ! - - Actions calling other actions in the same store must use `this.actionName(...)`
  ! - - If we do not use `this.actionName` it will not be properly mockable in tests.
  ! - - Computeds and refs will work fine, and should be called directly though.
*/

  async function setAccessToken(payload) {
    if (
      (payload && $lendioCookies.get('accessToken') !== payload) ||
      !accessToken.value
    ) {
      await $lendioCookies.set('accessToken', payload, {
        path: '/bp/',
        expires: 0.5,
      })
      accessToken.value = payload
    }
  }

  async function clearAccessToken() {
    accessToken.value = null
    await $lendioCookies.remove('accessToken', { path: '/bp/' })
  }

  function getAuthHeader({ acceptNoAuth = false, useCookies = false, silent = true, requestUrl = null } = {}) {
    function logNoAuthorization() {
      if (!silent) {
        log.error('Authorization from getAuthHeader is undefined', {
          authType: $lendioCookies.get('authType'),
          requestUrl,
          acceptNoAuth,
          useCookies,
          process: process.server ? 'server' : 'client',
        })
      }
    }
    const type = $lendioCookies.get('authType') ?? 'BEARER';
    let cookieToken = $lendioCookies.get('token')
    let token = rootStore.authToken
    switch (type) {
      case 'BEARER':
        if (token) {
          return `Bearer ${token}`
        }
        if (useCookies && cookieToken) {
          return `Bearer ${cookieToken}`
        }
        if (acceptNoAuth) {
          return
        }
      case 'LENDIO_JWT':
        if (token) {
          return `lendio-jwt ${token}`
        }
        if (useCookies && cookieToken) {
          return `lendio-jwt ${cookieToken}`
        }
        if (acceptNoAuth) {
          return
        }
      case 'EMBEDDED_JWT':
        token = rootStore.embeddedJwt
        cookieToken = $lendioCookies.get('embedded_jwt')
        if (token) {
          return `JWT ${token}`
        }
        if (useCookies && cookieToken) {
          return `JWT ${cookieToken}`
        }
    }
    logNoAuthorization()
    return false
  }


  async function setFreshLogin(payload) {
    if (payload) {
      freshLogin.value = payload
      await $lendioCookies.set('freshLogin', payload)
    } else {
      freshLogin.value = null
      await $lendioCookies.remove('freshLogin')
    }
  }

  const SESSION_REFRESH_THROTTLE_SECONDS = 60
  const SESSION_REFRESH_LEEWAY_SECONDS = 15
  let refreshingSessionInProgress = false

  async function queueSessionExtension() {
    const authType = $lendioCookies.get('authType')
    if (authType !== 'LENDIO_JWT' || !this.getAuthHeader()) {
      return
    }

    // If unable to access expiry from current token, refresh immediately
    const tokenExpiry = rootStore.getAuthTokenExpiry()
    const expiresFromNowSeconds = tokenExpiry ? tokenExpiry - Date.now() / 1000 : null
    if (!expiresFromNowSeconds) {
      return this.refreshSession()
    }

    // Early return if still more than 15 minutes until expiry
    const expiresFromNowMinutes = expiresFromNowSeconds / 60
    const refreshWithinMinutes = 15
    if(expiresFromNowMinutes > refreshWithinMinutes) {
      return
    }

    // Otherwise, let the throttle handle duplicated requests
    this.safeRefreshSession()
  }

  async function refreshSession() {
    const authType = $lendioCookies.get('authType')
    if (authType !== 'LENDIO_JWT' || !this.getAuthHeader()) {
      return
    }

    if (refreshingSessionInProgress) {
      return
    }
    refreshingSessionInProgress = true

    const newToken = await $axios
      .get(`${env('identityServiceUrl')}/auth/token-refresh`)
      .then((res) => {
        if (res && res.data && res.data.error) {
          log.error('Failed to refresh token', res.data.error)
          return
        }
        return res && res.data && res.data.data
      })
      .catch((err) => {
        if (err.response) {
          log.error('Failed to refresh token', err.response.data)
        } else {
          log.error('Failed to refresh token', err)
        }
        return
      })

    if (newToken) {
      await rootStore.setAuthToken(newToken, 'LENDIO_JWT')
    }
    refreshingSessionInProgress = false
  }

  /**
   * This action is fired on every mount of a layout. It should be quick,
   * and it's a catch-all for login tracking.
   */
  async function loadFreshLogin() {
    const login = freshLogin.value ?? await $lendioCookies.get('freshLogin')
    if (login == 'login') {
      await AnalyticsService.trackLogin($lendioCookies, $pinia)
    }
    this.setFreshLogin(null)
  }

  async function addLastTouch() {
    await appAnalyticsStore.loadTrackedCookies(null)
    const payload = {
      cookies: appAnalyticsStore.trackedCookies,
      tenantId: affiliateCustomizationStore.tenantId,
    }

    await $axios
      .post(`${env('apiUrl')}/user/add-last-touch`, payload)
      .then(() => appAnalyticsStore.clearTrackedCookies(null))
      .catch((err) => log.error(`Failed to add last touch: ${err.message}`, { err }))
  }

  async function extractUserProfile(route) {
    const hash = get(route, 'hash', null)

    if (
      !hash ||
      hash === '#' ||
      accessToken.value ||
      rootStore.isLoggedIn ||
      rootStore.authUser
    ) {
      return false
    }

    const parsedHash = hash
      .substr(1)
      .split('&')
      .reduce((carry, item) => {
        let [key, val] = item.split('=')
        carry[key] = val
        return carry
      }, {})

    if (!parsedHash.access_token || !parsedHash.id_token) {
      return false
    }

    await this.setAccessToken(parsedHash.access_token)
    route.hash = ''
    route.fullPath = route.path
    route.href = route.path
    return route
  }

  async function getBorrowers(force = false) {
    const currentUser = get(rootStore.authUser, 'id')
      ? rootStore.authUser
      : await rootStore.getCurrentUser({
          bypassRedirect: true,
        })

    if (!currentUser || typeof currentUser.id === 'undefined') return
    if (borrowers.value && force === false) return

    let res = await $axios
      .get(`${env('apiUrl')}/user/${get(currentUser, 'id')}/borrowers`)
      .catch((e) => {
        if (e.message !== 'Request failed with status code 401') {
          log.error('Unable to get borrowers', {
            err: e,
            errMessage: e.message,
            userId: currentUser.id || null,
            forceReload,
          })
        }
      })
    const data = get(res, 'data.data')
    if (!data) throw new Error('No data returned from user borrowers endpoint')
    const _borrowers = data
    borrowers.value = _borrowers
    return _borrowers
  }

  function setBorrowers(_borrowers) {
    borrowers.value = _borrowers
  }

  // TODO: Fully deprecate reauth, bpReauth, and safeBpReauth and use `queueSessionExtension` once bearer token structure is fully swapped for LENDIO_JWT
  async function reauth({ force = false } = {}) {
    if ($lendioCookies.get('authType') === 'LENDIO_JWT') {
      return this.queueSessionExtension()
    }

    if (force) {
      return this.bpReauth()
    }
    return this.safeBpReauth()
  }

  const BP_REAUTH_THROTTLE_MINUTES = 5
  async function bpReauth() {
    const now = Date.now()
    const currentUser = get(rootStore.authUser, 'id')
      ? rootStore.authUser
      : await rootStore.getCurrentUser({
          bypassRedirect: true,
        })
    if (!currentUser || typeof currentUser.id === 'undefined') return

    let res = await $axios
      .put(`${env('apiUrl')}/user/${get(currentUser, 'id')}/bp-re-auth`)
      .catch((e) => {
        if (e.message !== 'Request failed with status code 401') {
          log.error('Unable to reauth', {
            err: e,
            errMessage: e.message,
            userId: currentUser.id || null,
            forceReload,
          })
        }
      })

    const responseData = get(res, 'data.data')
    if (!responseData)
      throw new Error('No data returned from user reauth endpoint')
    if (responseData == get(currentUser, 'id')) {
      $lendioCookies.set('lastRunReauth', now)
      return true
    } else {
      return false
    }
  }

  return {
    //STATE
    accessToken,
    signInError,
    freshLogin,
    borrowers,

    //GETTERS
    hasOptimusUser,
    hasMultipleBorrowers,

    //ACTIONS
    setAccessToken,
    queueSessionExtension,
    refreshSession,
    safeRefreshSession: throttle(
      refreshSession,
      SESSION_REFRESH_THROTTLE_SECONDS * 1000,
      {
        leading: true,
        trailing: false,
      }
    ),
    setBorrowers,
    clearAccessToken,
    getAuthHeader,
    setFreshLogin,
    loadFreshLogin,
    addLastTouch,
    extractUserProfile,
    getBorrowers,
    reauth,
    bpReauth,
    safeBpReauth: throttle(
      bpReauth,
      BP_REAUTH_THROTTLE_MINUTES * 1000 * 60,
      {
        leading: true,
        trailing: false,
      }
    ),
  }
})
