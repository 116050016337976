import { useAffiliateCustomizationStore } from '~/store/affiliate-customization'

export default defineNuxtPlugin(async ({ $pinia }) => {
  const allowedBypassHostSlugs = ['cornerbank']
  if (process.server) {
    const host = useRequestHeaders(['host']).host
    if (host) {
      const {
        getNoAuthActiveAffiliateCustomizations,
        getNoAuthActiveAffiliateCustomizationsByHost
      } = useAffiliateCustomizationStore($pinia)
      const { overrideHost, overrideId } = useRoute().query
      const hostCanBypass = allowedBypassHostSlugs.some(slug => host.includes(slug))
      if (hostCanBypass) {
        let overrideValue
        if (overrideId) {
          overrideValue = await getNoAuthActiveAffiliateCustomizations(overrideId)
        }
        if (overrideHost) {
          overrideValue = await getNoAuthActiveAffiliateCustomizationsByHost(overrideHost)
        }
        if (overrideValue) {
          return
        }
      }
      return await getNoAuthActiveAffiliateCustomizationsByHost(host)
    }
  }
})
