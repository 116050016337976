<template>
  <div>
    <client-only><div hidden data-cy="client-loaded"></div></client-only>
    <NuxtLayout>
      <NuxtPage />
    </NuxtLayout>
  </div>
</template>

<script setup>
const config = useRuntimeConfig().public
const isTenant = config.tenantName != null && config.tenantName !== ''

// Scripts
const lendioPerformanceLogging = {
  innerHTML: `
    window.LendioPerformanceLogging = {
        debug: false,
        baseRouteChangeStart: {
          logged: false,
          startTime: null
        },
        baseTime: {
          startTime: new Date(),
          logged: false
        },
        calculateLoadTime: function(basetime, time) {
          time = time || new Date();
          basetime = basetime || this.baseTime.startTime;
          return time - basetime;
        }
      }
  `,
  type: 'text/javascript'
}
const adobeTarget = isTenant ? null : {
  src: '/bp/at.min.js',
}
const adobeLaunch =  isTenant ? null : {
  src: '/bp/adobe-launch.min.js',
}
const dreams = process.env.NODE_ENV === 'development' ?
    { src: 'http://local-interface.lendio.com/dreamsInterface.min.js'} :
    { src: 'https://tools.lendio.com/dreamsInterface.min.js'}

const finicityConnectScript = {
  src: "https://connect2.finicity.com/assets/sdk/finicity-connect.min.js",
  tagPosition: 'bodyClose',
  defer: true,
}
const gtm = {
  innerHTML: `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
    new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
    j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
    'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
  })(window,document,'script','dataLayer','GTM-P6PJHT')`,
  type: 'text/javascript'
}
const lordicon = {
  src: "https://cdn.lordicon.com/lordicon.js",
  tagPosition: 'bodyClose',
  defer: true,
}
const arrayAtPolyfill = {
  src: '/bp/array-at.polyfill.min.js',
}
useHead({
  base: {
    href: '/bp/'
  },
  meta: [
    { 'http-equiv': 'X-UA-Compatible', content: 'IE=edge,chrome=1' },
    { 'http-equiv': 'content-type', content: 'text/html; charset=UTF-8' },
    { 'name': 'URL', content: 'http://www.lendio.com' },
    { 'name': 'language', content: 'ENG' },
    { 'name': 'subject', content: '' },
    { 'name': 'viewport', content: 'width=device-width, initial-scale=1' },
    { 'name': 'msapplication-config', content: '/browserconfig.xml' },
    { 'name': 'theme-color', content: '#00bff0' },
  ],
  link: [
    { rel: 'apple-touch-icon', sizes: '180x180', href: '/images/apple-touch-icon.png'},
    { rel: 'manifest', sizes: '180x180', href: 'images/manifest.json'}
  ],
  titleTemplate: (string) => string
})

useHead({
  script: [
    lendioPerformanceLogging,
    gtm,
    finicityConnectScript,
    adobeTarget,
    adobeLaunch,
    dreams,
    lordicon,
    arrayAtPolyfill,
  ].filter(x => !!x)
})

onMounted(() => {
})

</script>
<style lang="scss">
  @import '/assets/styles/mp-transitions.scss'
</style>
