export default function (key, $config) {
  let env
  if ($config) {
    env = $config.public
  } else {
    env = process.client ? window.env : global.env
  }

  if (process.server && (env['environment'] === 'dev' || env.public && env.public['environment'] === 'dev')) {
    if (key === 'bpApiUrl') {
      return env && env.internalBpApiUrl || env.public && env.public.internalBpApiUrl
    }
    if (key === 'apiUrl') {
      return env && env.internalApiUrl || env.public && env.public.internalApiUrl
    }
  }

  if (env.debugEnabled === 'true') {
    console.log(`Environment Variable ${key} accessed:`, env[key])
  }
  return env[key] || env.public && env.public[key] || null
}
