import { defineStore } from 'pinia'

import { ApplicationConfigService } from '~/libs/store-services/application-config-service'
import appNameFromRoute from '~/libs/app-name-from-route'

import { useBorrowerStore } from '~/store/borrower'
import { useRootStore } from '~/store/root'
import { useAffiliateCustomizationStore } from '~/store/affiliate-customization'
import { useExperimentsStore } from '~/store/experiments';

export const useApplicationConfigStore = defineStore('applicationConfig', () => {
  const nuxtApp = useNuxtApp()
  const { $axios } = nuxtApp
  const route = useRoute()
  const applicationConfigService = new ApplicationConfigService($axios)
  const borrowerStore = useBorrowerStore()
  const rootStore = useRootStore()
  const affiliateCustomizationStore = useAffiliateCustomizationStore()
  const experimentsStore = useExperimentsStore()

  /*
  ███████ ████████  █████  ████████ ███████
  ██         ██    ██   ██    ██    ██
  ███████    ██    ███████    ██    █████
       ██    ██    ██   ██    ██    ██
  ███████    ██    ██   ██    ██    ███████
  STATE
  */
  const applicationConfig = ref(null)
  const navItems = ref([])
  const submitModuleMarketPlaceTestEnabled = ref(false)
  const stale = ref(true)
  const preloadedPages = ref([])
  const preloadedIdentifier = ref('')

  /*
   ██████  ███████ ████████ ████████ ███████ ██████  ███████
  ██       ██         ██       ██    ██      ██   ██ ██
  ██   ███ █████      ██       ██    █████   ██████  ███████
  ██    ██ ██         ██       ██    ██      ██   ██      ██
   ██████  ███████    ██       ██    ███████ ██   ██ ███████
  GETTERS
  */

  const appIdentifierRoute = computed(() => {
    return `${affiliateCustomizationStore.preapprovalOnly ? 'preapproval' : 'marketplace'}-app`
  })

  /*
   █████   ██████ ████████ ██  ██████  ███    ██ ███████
  ██   ██ ██         ██    ██ ██    ██ ████   ██ ██
  ███████ ██         ██    ██ ██    ██ ██ ██  ██ ███████
  ██   ██ ██         ██    ██ ██    ██ ██  ██ ██      ██
  ██   ██  ██████    ██    ██  ██████  ██   ████ ███████
  ACTIONS
  ! - - Actions calling other actions in the same store must use `this.actionName(...)`
  ! - - If we do not use `this.actionName` it will not be properly mockable in tests.
  ! - - Computeds and refs will work fine, and should be called directly though.
  */
  async function getByIdentifierAndSlug({ identifier, slug, direction }) {

    // return previously loaded value if we have it
    if (preloadedIdentifier.value !== identifier) {
      preloadedPages.value = []
    }

    if (!stale.value && !direction && this.checkSlugPreloaded(slug)) {
      return applicationConfig.value
    }

    const borrower = await borrowerStore.getBorrower()
    const borrowerId = borrower?.id
    const tenantId = affiliateCustomizationStore.tenantId

    const {appConfigIdentifier} = route.query

    if (appConfigIdentifier && borrowerStore.borrower?.isTest === 1) {
      identifier = appConfigIdentifier
    }

    const _applicationConfig = await applicationConfigService.getByIdentifierAndSlug({ identifier, slug, borrowerId, tenantId, direction })

    if (_applicationConfig.response) {
      if (_applicationConfig.response.status === 404) {
        return showError({
          statusCode: 404,
          statusMessage: `Page not found: /${identifier}-app`
        })
      } else {
        return showError({
          statusCode: 500,
          statusMessage: _applicationConfig.message
        })
      }
    }

    if (!borrowerId) {
      // In an unauth'd scenario, if we're using localStorage inject answers if we have no borrowerId
    }

    // update applicationConfig state
    applicationConfig.value = _applicationConfig
    stale.value = false

    // update navItems
    navItems.value = _applicationConfig.modules.map((module) => {
      return {
        url: `${identifier}-app/${module.urlSlug}`,
        label: module.displayName
      }
    })

    preloadedIdentifier.value = identifier
    preloadedPages.value = _applicationConfig.modules.reduce((pageSlugs, module) => {
      if (module.includesData) {
        pageSlugs.push(module.urlSlug)
      }
      return pageSlugs
    }, [])


    // return config
    return applicationConfig.value
  }

  async function saveAnswers({ answers: _answers = null } = {}) {
    if (!Object.keys(_answers).length) {
      return
    }

    const data = await applicationConfigService.saveAnswers({
      authenticated: Boolean(rootStore.authUser),
      answers: _answers,
      borrowerId: borrowerStore.borrowerId
    })
    stale.value = true

    if (data) {
      return Object.keys(_answers)
    }

    return []
  }

  async function updateSubmitModuleMarketPlaceTest() {
    await _updateExperimentEnabledStoreVar({
      experimentState: submitModuleMarketPlaceTestEnabled,
      experimentName: 'Submit Marketplace Test',
      allowedAppNames: ['marketplace']
    })
  }

  async function _updateExperimentEnabledStoreVar({experimentState, experimentName, allowedAppNames = [], testGroup = 1} = {}) {
    if (!experimentState || experimentState?.value === undefined || !experimentName || !allowedAppNames.length) {
      return
    }

    const appName = appNameFromRoute(route)
    if (!appName || !allowedAppNames.includes(appName)) {
      experimentState.value = false
      return
    }

    const user = rootStore.authUser
    if (!user) {
      experimentState.value = false
      return
    }

    await experimentsStore.fetchActiveExperimentsForCurrentUser()
    const isTestActive = experimentsStore.activeUserExperiments
      .some((userExperiment) => userExperiment.name === experimentName && userExperiment.group === testGroup)

    if (isTestActive) {
      experimentState.value = true
    }
  }

  function checkSlugPreloaded(slug = null) {
    if (!slug) {
      return false
    }
    return preloadedPages.value.includes(slug)
  }

  function getModuleFromSlug(slug) {
    let slugModule
    const _modules = applicationConfig.value?.modules
    if (_modules && _modules.length && slug) {
     slugModule = _modules.find((module) => {
      return module.urlSlug === slug
     })
    }
    return slugModule ?? null
  }

  return {
    // STATE
    applicationConfig,
    navItems,
    submitModuleMarketPlaceTestEnabled,
    stale,
    preloadedPages,
    preloadedIdentifier,

    // GETTERS
    appIdentifierRoute,

    // ACTIONS
    updateSubmitModuleMarketPlaceTest,
    getByIdentifierAndSlug,
    getModuleFromSlug,
    saveAnswers,
    checkSlugPreloaded
  }
})
