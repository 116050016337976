export default {
  'marketplace': [
    {
      name: 'basic-info',
      title: 'Basic info',
      routes: [
        {
          route: '/waypoint-0',
          enabled: false,
          enabledP: false,
        },
        {
          route: '/basic-info',
          visited: true,
          enabledP: ['progress', '!returnAppExperience'],
          isCompleteP: ['progress', 'basicInfoComplete'],
        },
        {
          route: '/updated-info',
          visited: true,
          enabledP: ['progress', 'returnAppExperience'],
          isCompleteP: ['progress', 'basicInfoComplete'],
        },
      ],
      adoptedRoutes: [
        '/basic-info-new/?[^\/]*',
        '/waypoint-0',
      ]
    },
    {
      name: 'funding-needs',
      title: 'Funding needs',
      routes: [
        {
          route: '/funding-needs',
          enabledP: ['fundingNeeds', 'showFundingNeeds'],
          isCompleteP: ['progress', 'fundingNeedsComplete']
        }
      ],
    },
    {
      name: 'owner-info',
      title: 'Owner info',
      routes: [
        {
          route: '/owner-info',
          enabledP: ['user', '!hasOptimusUser'],
          visitedP: ['progress', 'hasSeenOwnerInfo'],
          isCompleteP: ['progress', 'ownerInfoComplete']
        },
        {
          route: '/marketplace-app/owner-info-2',
          isCompleteP: ['progress', 'ownerInfoComplete'],
          visitedP: ['progress', 'hasSeenOwnerInfo'],
          enabledP: ['affiliateCustomizations', '!preapprovalOnly'],
        },
        {
          route: '/preapproval-app/owner-info-2',
          isCompleteP: ['progress', 'ownerInfoComplete'],
          visitedP: ['progress', 'hasSeenOwnerInfo'],
          enabledP: ['affiliateCustomizations', 'preapprovalOnly'],
        },
      ],
      adoptedRoutes: [
        '/marketplace-app/owner-info-3',
        '/marketplace-app/credit-check',
        '/preapproval-app/owner-info-3',
        '/preapproval-app/credit-check',
        '/newpass',
      ]
    },
    {
      name: 'business-info',
      title: 'Business info',
      isCompleteP: ['progress', 'businessInfoComplete'],
      routes: [
        {
          route: '/marketplace-app/business-info',
          enabledP: ['affiliateCustomizations', '!preapprovalOnly'],
        },
        {
          route: '/preapproval-app/business-info',
          enabledP: ['affiliateCustomizations', 'preapprovalOnly'],
        },
      ],
      adoptedRoutes: [
        '/marketplace-app/business-info',
        '/preapproval-app/business-info',
      ],
    },
    {
      name: 'documents',
      title: 'Documents',
      routes: [
        {
          route: '/marketplace-app/documents',
          enabledP: [
            ['document', 'documentsPageAllowed'],
            ['affiliateCustomizations', '!preapprovalOnly']
          ],
          isCompleteP: ['progress', 'documentsComplete'],
          requiredActions: {
            filterP: ['user', 'hasOptimusUser'],
            actionsP: [
              ['experienceAction', 'getExperience'],
            ],
          }
        },
      ],
      adoptedRoutes: [
        '/documents',
        '/documents/manual',
        '/documents/accounts',
        '/documents/sync',
        '/documents/statement-error',
        '/documents/sync-error',
      ]
    },
    {
      name: 'submit',
      title: 'Submit',
      routes: [
        {
          route: '/submit',
          isCompleteP: ['borrower', 'mpApplicationComplete'],
          requiredActions: {
            actionsP: [
              ['applicationConfigAction', 'updateSubmitModuleMarketPlaceTest'],
            ],
          },
          enabledP: [
            ['applicationConfig', '!submitModuleMarketPlaceTestEnabled'],
          ]
        },
        {
          route: '/marketplace-app/submit',
          isCompleteP: ['borrower', 'mpApplicationComplete'],
          requiredActions: {
            actionsP: [
              ['applicationConfigAction', 'updateSubmitModuleMarketPlaceTest'],
            ],
          },
          enabledP: [
            ['applicationConfig', 'submitModuleMarketPlaceTestEnabled'],
          ]
        },
      ]
    }
  ],
  'renewal' : [
    {
      name: 'renewal',
      title: 'Verify Information',
      visited: true,
      routes: [
        {route: '/renewal'},
        {route: '/renewal/business-info'}
      ]
    },
    {
      name: 'new-loan-requirements',
      title: 'New Loan Requirements',
      routes: [
        {route: '/renewal/new-loan-requirements'}
      ]
    },
    {
      name: 'documents',
      title: 'Financial Review',
      routes: [
        {route: '/renewal/documents'},
      ],
      adoptedRoutes: [
        '/renewal/documents/manual'
      ]
    },
    {
      name: 'credit-check',
      title: 'Credit Check',
      routes: [
        {route: '/renewal/credit-check'},
        {route: '/renewal/credit-check/review'}
      ]
    },
    {
      name: 'submit',
      title: 'Terms and Agreement',
      enabledP: ['borrower', 'signatureExpired'],
      routes: [
        {route: '/renewal/submit'}
      ]
    },

  ],
  'embedded' : [
    {
      name: 'owner-info',
      title: 'Owner Info',
      routes: [
        {
          route: '/embedded/el-app/basic-info',
          visited: true,
        },
        {
          route: '/embedded/el-app/owner-info',
        },
      ]
    },
    {
      name: 'business-info',
      title: 'Business Info',
      routes: [
        {
          route: '/embedded/el-app/business-info',
        },
      ],
      adoptedRoutes: [
        '/embedded/el-app/owner-info-3',
        '/embedded/el-app/credit-check',
      ]
    },
    {
      name: 'documents',
      title: 'Documents',
      routes: [
        {
          route: '/embedded/el-app/documents',
        },
      ],
      adoptedRoutes: [
        '/embedded/documents',
        '/embedded/documents/results',
        '/embedded/documents/manual',
        '/embedded/documents/accounts',
        '/embedded/documents/sync',
        '/embedded/documents/statement-error',
        '/embedded/documents/sync-error',
      ]
    },
    {
      name: 'submit',
      title: 'Submit',
      routes: [
        {
          route: '/embedded/submit',
        },
      ]
    },
  ],
  'sba' : [
    // Show a bank statments navItem that links to documents results page
    {
      name: 'bank-statements',
      title: 'Bank Statements',
      routes: [
        {
          route: '/documents/results',
          isCompleteP: ['document', 'hasOfTypes', [{ type: 'bankStatement', min: 3 }]],
          visited: true
        },
      ],
      visited: true,
    },
    {
      name: 'tax-returns',
      title: 'Tax Returns',
      routes: [
        {
          route: '/sba/tax-returns',
          isCompleteP: ['document', 'hasOfTypes', [{ type: 'businessTaxReturns', min: 2 }, { type: 'personalTaxReturns', min: 2 }]],
          progressCardMissingText: 'Finish uploading your tax returns for your enhanced loan application.'
        },
      ],
      visited: true,
    },
    {
      name: 'debt-schedule',
      title: 'Debt Schedule',
      routes: [
        {
          route: '/sba/debt-schedule',
          progressCardMissingText: 'Finish uploading your Debt Schedule for your enhanced loan application.'
        },
        {
          route: '/sba/debt-schedule-upload',
          enabledP: ['borrower', 'hasBusinessDebt'],
          isCompleteP: ['borrower', 'debtScheduleComplete'],
          progressCardMissingText: 'Finish uploading your Debt Schedule for your enhanced loan application.'
        },
      ],
      visited: true,
    },
    {
      name: 'profit-and-loss',
      title: 'YTD Profit & Loss',
      routes: [
        {
          route: '/sba/profit-and-loss',
          isCompleteP: ['document', 'hasOfTypes', [{ type: 'pLCurrentYear' }]],
          progressCardMissingText: 'Finish uploading your YTD P&L Statement for your enhanced loan application.'
        },
      ],
      visited: true,
    },
    {
      name: 'balance-sheet',
      title: 'YTD Balance Sheet',
      routes: [
        {
          route: '/sba/balance-sheet',
          isCompleteP: ['document', 'hasOfTypes', [{ type: 'balanceSheet' }]],
          progressCardMissingText: 'Finish uploading your YTD Balance Sheet for your enhanced loan application.'
        },
      ],
      visited: true,
    }
  ],
  'sba-complete': [
    {
      name: 'sba-complete-basic-info',
      title: 'Basic info',
      routes: [
        {
          route: '/sba-complete/basic-info',
          visited: true,
        }
      ]
    },
    {
      name: 'sba-complete-owner-info',
      title: 'Owner info',
      routes: [
        {
          route: '/sba-complete/owner-info',
          enabledP: ['user', '!hasOptimusUser'],
        },
        {
          route: '/sba-complete/owner-info/owner-info-2',
        },
        {
          route: '/sba-complete/owner-info/owner-info-3',
        }
      ],
    },
    {
      name: 'sba-complete-business-info',
      title: 'Business info',
      routes: [
        {
          route: '/sba-complete/business-info'
        },
        {
          route: '/sba-complete/business-info/business-info-2',
          enabledP: ['questions', 'hasQuestions', 'businessFinancialQuestions']
        },
        {
          route: '/sba-complete/business-info/business-info-3',
          enabledP: ['questions', 'hasQuestions', 'additionalQuestions']
        }
      ]
    },
    {
      name: 'sba-complete-bank-statements',
      title: 'Bank statements',
      routes: [
        {
          route: '/sba-complete/documents',
          requiredActions: {
            filterP: ['user', 'hasOptimusUser'],
            actionsP: [
              ['documentAction', 'getDocuments'],
            ],
          }
        },
        {
          route: '/sba-complete/documents/results',
          enabledP: ['document', 'hasOfTypes', [{ type: 'bankStatement', min: 3 }]],
          visitedP: ['document', 'hasOfTypes', [{ type: 'bankStatement', min: 3 }]],
          requiredActions: {
            filterP: ['user', 'hasOptimusUser'],
            actionsP: [
              ['documentAction', 'getDocuments'],
            ],
          }
        }
      ],
      adoptedRoutes: [
        '/sba-complete/documents/manual',
        '/sba-complete/documents/accounts',
        '/sba-complete/documents/sync',
        '/sba-complete/documents/statement-error',
        '/sba-complete/documents/sync-error',
      ]
    },
    {
      name: 'sba-complete-tax-returns',
      title: 'Tax returns',
      routes: [
        {
          route: '/sba-complete/tax-returns',
          isCompleteP: ['document', 'hasOfTypes', [{ type: 'businessTaxReturns', min: 2 }, { type: 'personalTaxReturns', min: 2 }]],
          progressCardMissingText: 'Finish uploading your tax returns for your SBA loan application.',
          requiredActions: {
            filterP: ['user', 'hasOptimusUser'],
            actionsP: [
              ['documentAction', 'getDocuments'],
            ],
          }
        },
      ],
    },
    {
      name: 'sba-complete-debt-schedule',
      title: 'Debt schedule',
      routes: [
        {
          route: '/sba-complete/debt-schedule',
          progressCardMissingText: 'Finish uploading your Debt Schedule for your SBA loan application.'
        },
        {
          route: '/sba-complete/debt-schedule-upload',
          enabledP: ['borrower', 'hasBusinessDebt'],
          isCompleteP: ['borrower', 'debtScheduleComplete'],
          progressCardMissingText: 'Finish uploading your Debt Schedule for your SBA loan application.'
        },
      ]
    },
    {
      name: 'sba-complete-profit-and-loss',
      title: 'YTD profit & loss',
      routes: [
        {
          route: '/sba-complete/profit-and-loss',
          isCompleteP: ['document', 'hasOfTypes', [{ type: 'pLCurrentYear' }]],
          progressCardMissingText: 'Finish uploading your YTD P&L Statement for your SBA loan application.'
        },
      ],
    },
    {
      name: 'sba-complete-balance-sheet',
      title: 'YTD balance sheet',
      routes: [
        {
          route: '/sba-complete/balance-sheet',
          isCompleteP: ['document', 'hasOfTypes', [{ type: 'balanceSheet' }]],
          progressCardMissingText: 'Finish uploading your YTD Balance Sheet for your SBA loan application.'
        },
      ],
    },
    {
      name: 'sba-complete-submit',
      title: 'Submit',
      routes: [
        {
          route: '/sba-complete/submit',
          isCompleteP: ['borrower', 'mpApplicationComplete']
        },
      ]
    },
  ],
}
