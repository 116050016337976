import Pusher from 'pusher-js'
import env from '~/libs/env'
import { useBorrowerStore } from '~/store/borrower'
import { useUserStore } from '~/store/user'

let pusherInstance
let BorrowerChannel

export default {
  connect ($pinia, $lendioCookies) {
    const pusherKey = env('pusherKey')
    const userStore = useUserStore($pinia)
    const borrowerStore = useBorrowerStore($pinia)

    const Authorization = userStore.getAuthHeader()

    // Only logged-in users get to connect
    if (!Authorization || !pusherKey) {
      return false
    }

    const apiUrl = $lendioCookies.get('authType') === 'LENDIO_JWT'
      ? env('apiWgUrl')
      : env('apiUrl')

    // Connect to pusher itself
    pusherInstance = new Pusher(pusherKey, {
      cluster: env('pusherCluster'),
      channelAuthorization: {
        endpoint: `${apiUrl}/authorization/pusher`,
        headers: {Authorization}
      },
    })

    const borrowerChannelName = `private-borrower-${borrowerStore.borrowerId}-${env('environment')}-notification-channel`
    BorrowerChannel = pusherInstance.subscribe(borrowerChannelName)

    return pusherInstance
  },

  getInstance () {
    return pusherInstance || this.connect()
  },

  getBorrowerChannel () {
    return BorrowerChannel
  }
}
