import { embeddedErrorRouting } from '~/libs/embedded'
import get from 'lodash/get'
import signup from '~/libs/legacy-redirects/signup.js'
import lenderProfiles from '~/libs/legacy-redirects/lender-profiles.js'
import { useDealsStore } from '~/store/deals'
import { useRootStore } from '~/store/root'
import { useProgressStore } from '~/store/progress'
import { useBorrowerStore } from '~/store/borrower'
import { useAffiliateCustomizationStore } from '~/store/affiliate-customization'

export default defineNuxtRouteMiddleware(async (to) => {
  const nuxtApp = useNuxtApp()
  const { $pinia, $lendioCookies } = nuxtApp
  const piniaRootStore = useRootStore($pinia)
  const dealsStore = useDealsStore($pinia)
  const progressStore = useProgressStore($pinia)
  const borrowerStore = useBorrowerStore($pinia)
  const affiliateCustomizationStore = useAffiliateCustomizationStore($pinia)
  let req = null

  if (process.server) {
    req = nuxtApp.ssrContext.event.node.req
  }

  const config = useRuntimeConfig()
  const useDocSuccessPage = config.public.useDocsOnlyFlow === 'true'
  const defaultAppRedirect = useDocSuccessPage ? '/documents' : '/resume-app'

  const rules = [
    {
      regExp: /.*\/overview.*/,
      // will need to be changed to redirect: 'portal' once this is live for all borrowers
      redirect: '/portal'
    },
    {
      regExp: /.*\/continue.*/,
      // will need to be changed to redirect: 'portal' once this is live for all borrowers
      redirect: '/portal'
    },
    // will need to add a new rule to redirect /portal to /dashboard once this is live for all borrowers
    {
      regExp: /.*\/no-cc-options.*/,
      redirect: '/basic-info'
    },
    {
      regExp: /.*\/app(?!roval).*/,
      redirect: defaultAppRedirect
    },
    ...signup,
    ...lenderProfiles
  ]

  const query = to.query
  const rule = to.path ? rules.find(testRule.bind(null, to.path)) : null
  if (rule) {
    if ('hpRedirect' in rule) {
      return nuxtApp.runWithContext(() => navigateTo(`${config.public.baseUrl}${rule.redirect}`,{external: true}))
    } else {
      $lendioCookies.set('referral_url', req?.headers?.referer || null)
      const fullPath = getRedirectPath(query, rule)
      return nuxtApp.runWithContext(() => navigateTo(fullPath, { redirectCode: 301, replace: true }))
    }
  }

  if (!affiliateCustomizationStore.isSupportedPage(to)) {
    if (to.fullPath.includes('embedded')) {
      // Without this embedded qualifier, this log was very spammy. Will need to re-evaluate why is catches so much traffic, but need to ensure embedded traffic first.
      // Still super spammy, will revisits. TODO
      // log.warning('Embedded navigation failure', { message: 'Attempted navigation to unsupported page.', to })
    }
    return nuxtApp.runWithContext(() => navigateTo('/404'))
  }

  // Redirect from old ppp routes to portal
  if (['/covid-relief/ppp-dashboard', '/covid-relief/thank-you'].includes(to.path)) {
    return nuxtApp.runWithContext(() => navigateTo("/portal/options?currentApplication='PPP'"))
  }

  if (to.path.includes('bp/')) {
    const scrubbedPath = to.path.substring(to.path.lastIndexOf('bp/') + 2)
    return nuxtApp.runWithContext(() => navigateTo(scrubbedPath), { redirectCode: 301, replace: true })
  }

  if (to.path.includes('login-social') || to.path.includes('login/social') ) {
    return nuxtApp.runWithContext(() => navigateTo('/login'), { redirectCode: 301, replace: true })
  }

  if (to.path === '/dashboard') {
    return nuxtApp.runWithContext(() => navigateTo('/portal'), { redirectCode: 301, replace: true })
  }

  if (to.path === '/update-info') {
    return nuxtApp.runWithContext(() => navigateTo('/updated-info'), { redirectCode: 301, replace: true })
  }

  // Redirect from old offer routes to new options route, currently it's temporarily (307 status code) to avoid browser cache.
  if (['/portal/offers', '/portal/loan-offers'].includes(to.path)) {
    return nuxtApp.runWithContext(() => navigateTo({ path: '/portal/options', query: to.query, hash: to.hash }, { redirectCode: 307, replace: true }))
  }

  // ------------ START EMBEDDED APP REDIRECT LOGIC ------------

  const isEmbeddedUser = piniaRootStore.authUser && piniaRootStore.authUser.isEmbedded
  const isNotEmbeddedUser = piniaRootStore.authUser && !piniaRootStore.authUser.isEmbedded

  // if a non-embedded is trying to visit an embedded route, redirect to 404
  if (/\/embedded(\/|$)/.exec(to.path) && isNotEmbeddedUser) {
    return nuxtApp.runWithContext(() => navigateTo('/404'))
  }

  // if the route is embedded, not /embedded/401, and user is not auth'd, redirect to /embedded/401
  if (
    /\/embedded(\/|$)/.exec(to.path) &&
    to.path !== '/embedded/401' &&
    !piniaRootStore.authUser
  ) {
    // const errorObj = {
    //   location: 'LEGACY_REDIRECT',
    //   isEmbedded: true
    // }
    // log.info('AUTHENTICATION FAILURE:' + JSON.stringify(errorObj))
    return nuxtApp.runWithContext(() => navigateTo(embeddedErrorRouting(401)))
  }

  if (isEmbeddedUser) {
    // if an embedded user is trying to visit a non-embedded route, redirect to /embedded/404
    if (!/\/embedded(\/|$)/.exec(to.path)) {
      return nuxtApp.runWithContext(() => navigateTo(embeddedErrorRouting(404)))
    }
    // if the route is /embedded?initialLoad=true
    if (to.path === '/embedded' && to.query.initialLoad) {
      await progressStore.fetchApplications()
      const deals = await getDeals(dealsStore)
      const borrower = get(borrowerStore, 'borrower', null)
      const hasFundedDeals = deals.filter(d => d.stage === 'funded').length > 0
      const dealInClosingStatus = deals.find(d => ['contractRequested', 'contractIn', 'contractOut', 'funding'].includes(d.status))
      if (dealInClosingStatus || hasFundedDeals) {
        return nuxtApp.runWithContext(() => navigateTo('/embedded/portal/dashboard'))
      }
      // if borrower is partner import without an active preapproval or funded deals, do not pass go
      if (borrower && borrower.leadSource === 'Partner Import' && !borrower.hasActivePreapproval && !hasFundedDeals) {
        return nuxtApp.runWithContext(() => navigateTo('/embedded?preapprovalExpired=true'))
      }
      // if app is expired and borrower has not funded, clear app progress and redirect to beginning
      if (progressStore.isEmbeddedAppExpired && !hasFundedDeals) {
        await progressStore.updateApplication({ update: 'reset', type: 'embedded' })
        return nuxtApp.runWithContext(() => navigateTo('/embedded?appExpired=true'))
      // if app is complete and not expired or borrower has funded, redirect to portal
      } else if (progressStore.isEmbeddedAppComplete) {
        return nuxtApp.runWithContext(() => navigateTo('/embedded/portal'))
      // else, execute resume app logic
      } else {
        await progressStore.getVisitedRoutes()
        const resumeRoute = progressStore.resumeApplicationRoute('embedded')
        if (resumeRoute !== to.path) {
          return nuxtApp.runWithContext(() => navigateTo(progressStore.resumeApplicationRoute('embedded')))
        }
      }
    }
  }

  // ------------ END EMBEDDED APP REDIRECT LOGIC ------------

  if (to.path === '/profile' || to.path === '/portal/profile') {
    return nuxtApp.runWithContext(() => navigateTo('/portal/settings'))
  }

  if (to.path === '/login-pathway') {
    const completedApp = get(borrowerStore, 'borrower.applicationComplete', null)
    let token

    if (process.server) {
      token = $lendioCookies.get('token')
      piniaRootStore.setAuthToken(token)
    } else {
      token = get(piniaRootStore, 'authToken', null)
    }

    const redirectUrl = completedApp
      ? '/portal'
      : token && piniaRootStore.isLoggedIn
        ? '/resume-app'
        : '/basic-info'
    return nuxtApp.runWithContext(() => navigateTo(redirectUrl))
  }

  if (/\/renewal(\/|$)/.exec(to.path) && piniaRootStore.authUser) {
    const deals = await getDeals(dealsStore)
    const completedApp = get(borrowerStore, 'borrower.applicationComplete', null)
    if (!hasFundedMPDeal(deals) || !completedApp) {
      return nuxtApp.runWithContext(() => navigateTo('/basic-info'))
    }
  }

  // 404
  // if (to.path !== '/404' && to.matched && !to.matched.length) {
  //   if (isEmbeddedUser) {
  //     return navigateTo('/embedded/404');
  //   } else {
  //     return navigateTo('/404');
  //   }
  // }

  // referral page
  if (to.path === '/referral' || to.path === '/referral/') {
    let landing_page = to.fullPath;
    return nuxtApp.runWithContext(() => navigateTo('/referral/brett-child?landing_page=' + landing_page))
  }
})

async function getDeals(_dealsStore) {
  let deals = get(_dealsStore, 'deals', [])
  if (!deals.length) {
    await _dealsStore.getDealsWithOffers()
  }
  deals = get(_dealsStore, 'deals', [])
  return deals
}

function hasFundedMPDeal(deals) {
  return deals && deals.some(deal => {
    return /funded/i.exec(deal.stage)
  })
}

function testRule(path, rule) {
  if (rule.hpRedirect && rule.regExp.test(path)) {
    return rule
  } else {
    return rule.regExp.test(path)
  }
}

function getRedirectPath(reqQuery, rule) {
  let path = rule.redirect
  let query = reqQuery
  if (typeof path === 'object') {
    path = rule.redirect.path
    query = Object.assign({}, reqQuery, rule.redirect.query || {})
  }
  const fullPath = path +'?'+ Object.entries(query).map(([k, v]) => `${k}=${v}`).join(',')
  return fullPath
}
