<template>
  <div class="container w-100 tk-proxima-nova"
       :class="{'customized-affiliate': !!affiliateCustomizations}"
       :style="appStyleProps">
    <div class="row justify-content-center">
      <div class="card border-0 text-center px-0" style="width: 30rem">
        <NuxtImg
          v-if="error.statusCode === 404"
          src="/images/404_image.svg"
          width="400"
          height="400"
          class="card-img-top"
          alt="404 error"
        />
        <NuxtImg
          v-else
          src="/images/500_image.svg"
          width="400"
          height="400"
          class="card-img-top"
          alt="500 error"
        />
        <div class="card-body text-center">
          <h6 class="type-400 fw-bolder">ERROR {{ error.statusCode }}</h6>
          <h3 v-if="error.statusCode === 404" class="p-2 p-lg-0 mb-1 mb-lg-2">Oh no! Something went wrong.</h3>
          <h3 v-else class="p-2 p-lg-0 mb-1 mb-lg-2">Sorry, an unexpected error occured.</h3>
          <p v-if="error.statusCode === 404" class="type-400 mb-5 pb-1 pb-lg-0">
            This page was not found. Check that your URL is spelled correctly or
            head to the home page.
          </p>
          <p v-else class="type-400 mb-5 pb-1 pb-lg-0">
            We’re not exactly sure what happened, but our servers say something is wrong. We’re working on fixing the problem.
          </p>
          <button @click="resumeApp()" :disabled="navigating" class="btn btn-primary btn-sm">
            <ButtonLoadingSpinner v-if="navigating"></ButtonLoadingSpinner>Resume Application
          </button>
          <div class="py-5 m-8 fw-bold" v-if="isDev">
            {{ error.message }}
          </div>
          <div class="py-5 m-8 fw-bold" v-if="isDev">
            Check console for more information
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import typeKitInit from '~/libs/typekit'
import { mapState } from "pinia";
import { useAffiliateCustomizationStore } from '~/store/affiliate-customization';

export default {
  setup(props) {
    const $config = useRuntimeConfig().public
    const isDev = $config.environment === 'dev'
    const navigating = ref(false)

    function simNavigation() {
      navigating.value = true
      setTimeout(function() {
        navigating.value = false
      }, 5000)
    }

    if (!process.client && props.error.statusCode !== 404) {
      log.error(props.error)
    }

    onMounted(() => {
      if (isDev) {
        console.log(props.error.message, props.error)
      }
      typeKitInit()
    })

    return {
      simNavigation,
      navigating,
      isDev
    }
  },
  props: ['error'],
  computed: {
    ...mapState(useAffiliateCustomizationStore, ['affiliateCustomizations', 'appStyleProps']),
  },
  methods: {
    resumeApp() {
      this.simNavigation()
      return navigateTo('/bp/resume-app', { external: true })
    }
  }
}
</script>

<style lang="scss" scoped>
@import 'assets/styles/affiliate-customizations/affiliate-customizations-404-page';
@import 'lendio-spinners';

.card-body {
  margin-top: -42px;
  @include media-breakpoint-down(lg) {
    margin-top: -68px;
  }
}

.mh-100vh {
  min-height: 100vh;
}

#app {
  flex: 1 0 auto;
  padding-bottom: 80px;
}

// We have to override some styles with important to get higher precedence than the scoped styles in the footer
#base-footer {
  .base-footer-bottom {
    margin: 0 auto;
    max-width: 1200px;
    background-color: $background-100 !important;
  }

  .text-container {
    padding: 10px 0 !important;
  }
}

.customized-affiliate {
  @include affiliateCustom404();
}
</style>

<style lang="scss">
/* Sticky Footer */
html,
body,
#__nuxt,
#main-flex {
  /* Any container housing the flexboxes needs to be AT LEAST 100% height! */
  min-height: 100vh;
}
</style>
