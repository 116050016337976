import {localStorageService} from "~/libs/local-storage.service"
import { useAppAnalyticsStore } from '~/store/app-analytics'

export const MBOX_DETAILS = {
  ACTION_ITEMS: {
    NAME: 'test-action-items',
    routeCheck: (routePath) => {
      return routePath === '/portal'
    }
  },
  APPROVALS_DISPLAY: {
    NAME:'test-approvals-display',
    routeCheck: (routePath) => {
      return routePath === '/portal'
    },
  },
  APPROVALS_CALCULATOR: {
    NAME:'adjustment-calculator',
    routeCheck: (routePath) => {
      return routePath === '/portal/offers-2' || routePath === '/portal/adjust-offer'
    },
  },
  CREDIT_PULL_LOCATION: {
    NAME:'test-credit-pull-location',
    routeCheck: (routePath, pinia) => {
      return false
    },
    cacheKey: 'testCreditPullLocation',
  },
  DOCUMENTS_FAST_FLOW: {
    NAME: 'test-documents-fast-flow',
    routeCheck: (routePath) => {
      return ['/documents'].includes(routePath)
    },
    cacheKey: 'testDocumentsFastFlow',
  },
  EMBEDDED_FAST_TRACK: {
    NAME: 'test_fast_track',
    routeCheck: (routePath) => {
      const routeWhitelist = ['/borrower-info-2']
      return routeWhitelist.includes(routePath)
    },
    cacheKey: 'testEmbeddedFastTrack'
  },
  INTERCOM_FIN_TRIAL: {
    NAME: 'intercom-fin-trial',
    routeCheck: () => {
      return false // handling this from chat-service plugin
    }
  },
  MANUAL_ONLY_DOCUMENTS: {
    NAME: 'test-manual-only-documents',
    routeCheck: (routePath) => {
      const routeWhitelist = ['/documents']
      return routeWhitelist.includes(routePath)
    },
    cacheKey: 'testManualOnlyDocuments',
  },
  MANUAL_DOCUMENT_REDIRECT: {
    NAME: 'test-redirect-to-manual',
    routeCheck: (routePath) => {
      const routeWhitelist = ['/documents']
      return routeWhitelist.includes(routePath)
    },
  },
  MANUAL_DOCUMENTS_RECOMMENDED: {
    NAME: 'test-manual-documents-recommended',
    routeCheck: (routePath) => {
      const routeWhitelist = ['/documents']
      return routeWhitelist.includes(routePath)
    },
    cacheKey: 'testManualDocumentsRecommended',
  },
  LAST_FOUR_DIGIT_SSN: {
    NAME: 'test-last-four-digit-ssn',
    routeCheck: (routePath) => {
      const routeWhitelist = ['/owner-info']
      return routeWhitelist.includes(routePath)
    },
    cacheKey: 'testLastFourDigitSSN',
  },
  TEST_NAICS_MARKETPLACE: {
    NAME: 'naics-marketplace',
    routeCheck: (routePath) => {
      return routePath === '/basic-info'
    },
    cacheKey: 'naicsMarketplace',
  },
  TEST_PORTAL_NEW_APPLICATION_REQUIREMENTS: {
    NAME: 'portal-new-application-requirements',
    routeCheck: (routePath) => {
      const routeWhitelist = ['/portal']
      return routeWhitelist.includes(routePath)
    },
    cacheKey: 'portalNewApplicationRequirements',
  },
  TEST_FUNDING_NEEDS: {
    NAME: 'funding-needs',
    routeCheck: (routePath) => {
      return routePath === '/funding-needs'
    },
    cacheKey: 'fundingNeeds',
  },
  TEST_IDENTITY_MULTIPLE_MFA_METHODS: {
    NAME: 'test-identity-mutliple-mfa-methods',
    routeCheck: (routePath) => {
      return routePath.includes('identity')
    },
    cacheKey: 'multipleMfaMethods',
  },
}

/**
Params must be an object. It can contain custom variables, but must
  * include `orderId, orderTotal, and productPurchasedId` because Adobe wants that.
  *
  * @param {
      'orderId': this.borrower.borrowerId,
      'orderTotal': '0',
      'productPurchasedId': '0',
    }
 */
    export function trackEvent (mbox, params) {
  const defaultParams = {
    'orderTotal': '0',
    'productPurchasedId': 'da_event',
    'orderId': '0'
  }
  const filteredParams = Object.entries(params).reduce((a,[key, val]) => (val ? (a[key] = val, a) : a), {})
  const combinedParams = filteredParams ? { ...defaultParams, ...filteredParams } : defaultParams
  window.adobe.target.trackEvent({
    'mbox': mbox,
    'params': combinedParams
  })
}

export function getOffer (testParams, applyOffer = false) {
  const targetObj = {
    offer: null,
    error: false,
  }
  if (!testParams.mbox) {
    // log.error(new Error('MBOX not provided to Adobe Target'), testParams)
    return (targetObj)
  }
  return new Promise((resolve, reject) => {
    const defaultParams = {
      "success": targetOffer => {
        if (targetOffer) targetObj.offer = targetOffer
        if (applyOffer) window.adobe.target.applyOffer({ mbox: testParams.mbox, offer: targetOffer })
        resolve(targetObj)
      },
      "error": (status, error) => {
        targetObj.error = true
        // Commenting out until prioritized to address time out error, and firefox strict mode
        // log.error(new Error(`Failed to retrieve Adobe Target results --MBOX: ${testParams.mbox} --status: ${status || null} --error: ${error || null}`), error)
        resolve(targetObj)
      }
    }
    const requestParams = { ...defaultParams, ...testParams}

    if (requestParams.mbox) {
      if (window.adobe.target.VERSION === "") {
        targetObj.error = true
        resolve(targetObj)
        return
      }
      window.adobe.target.getOffer(requestParams)
    } else {
      resolve(targetObj)
    }
  })
}

export function getCacheKey (mboxName) {
  const mbox = Object.keys(MBOX_DETAILS)
    .map(key => MBOX_DETAILS[key])
    .find(mbox => mbox.NAME == mboxName)
  if (!mbox) {
    console.error(new Error('Cannot find mbox: '+mboxName))
    return mboxName
  }
  // use hasOwnProperty to ensure false prevents caching
  return mbox.hasOwnProperty('cacheKey') ? mbox.cacheKey : mboxName
}

/**
 * Use this method when you want to check a borrower's adobe target tests even if they have switched
 * devices.
 *
 * Checks for adobe target experiences in the following order:
 * 1. Does borrower have a test saved in localStorage?
 * 2. Does borrower have an active experiment in the DB?
 * 3. Does the route have a query param for the test?
 * 4. If the first three are false then check target to see if the test is active
 *
 * @param adobeTargetTestName MBOX_DETAILS.[test].NAME
 * @param borrowerExperiments
 * @param searchTerm
 * @param route
 * @param dispatch
 * @param pinia
 * @returns {Promise<{}>}
 */
export async function checkAdobeTargetExperience(adobeTargetTestName, borrowerExperiments, searchTerm, route, pinia) {
  if (process.client && checkAdobeTargetLocalStorageItem(searchTerm)) {
    return true
  } else if (process.client && route && checkAdobeOverride({route, searchTerm})) {
    return true
  } else if (Array.isArray(borrowerExperiments) && checkAdobeTargetExperiment(searchTerm, borrowerExperiments)) {
    return true
  } else {

    return useAppAnalyticsStore(pinia).checkSingleTargetOffer({mbox: adobeTargetTestName, searchTerm}, {root: true})
  }
}

export function checkAdobeOverride({route, searchTerm}) {
  return process.client && route.query && route.query.hasOwnProperty(searchTerm);
}

/**
 * This function checks each adobe target test that is configured to be checked on the given route.
 * @param {NuxtRoute} route
 * @param {pinia} pinia
 * @returns {Promise} target requests
 */
export function checkAdobeTargetForRoute(route, pinia) {
  const requests = []
  for (const key in MBOX_DETAILS) {
    const MBOX = MBOX_DETAILS[key]
    if (MBOX.routeCheck && MBOX.routeCheck(route.path, pinia)) {
      const payload = MBOX.ADDITIONAL_PARAM ? { mbox: MBOX.NAME, params: MBOX.ADDITIONAL_PARAM } : { mbox: MBOX.NAME || undefined }

      requests.push(useAppAnalyticsStore(pinia).checkAllTargetOffers(payload))
    }
  }
  return Promise.all(requests)
}

/**
 * Note: For this to return true the name of the experiment in the DB must match the searchTerm of the test in
 * Adobe Target
 * @param searchTerm
 * @param borrowerExperiments
 * @returns {Boolean}
 */
export function checkAdobeTargetExperiment(searchTerm, borrowerExperiments) {
  return Array.isArray(borrowerExperiments) && borrowerExperiments.map(({name}) => name).includes(searchTerm)
}

export function checkAdobeTargetLocalStorageItem(searchTerm) {
  const currentStorage = JSON.parse(localStorageService.getItem('borrowerAdobeTargetTests'))
  return currentStorage && currentStorage[searchTerm]
}

export function setAdobeTargetLocalStorageItem(searchTerm, payload) {
  if (process.client) {
    const currentStorage = JSON.parse(localStorageService.getItem('borrowerAdobeTargetTests'))
    localStorageService.setItem('borrowerAdobeTargetTests', JSON.stringify({
      ...currentStorage,
      [searchTerm]: payload
    }))
  }
}

export function getAdobeTargetExperimentGroupsFromLocalStorage() {
  if (process.client) {
    const adobeTargetTests = JSON.parse(localStorageService.getItem('borrowerAdobeTargetTests')) || []
    return adobeTargetTests && Object.keys(adobeTargetTests).map(name => ({ 'name': name }))
  }
  return []
}

export default {
  MBOX_DETAILS,
  trackEvent,
  getOffer,
  getCacheKey,
  checkAdobeTargetExperience,
  checkAdobeOverride,
  checkAdobeTargetForRoute,
  checkAdobeTargetExperiment,
  checkAdobeTargetLocalStorageItem,
  setAdobeTargetLocalStorageItem,
  getAdobeTargetExperimentGroupsFromLocalStorage,
}
